import { API, UseCases } from "@aptus/frontend-core";
// import { DeduplicationUtils } from "utils/DeduplicationUtils";
import { TravelRoute } from "./models/travelRoute";

export type TravelRoutesAPI = API<TravelRoute[]>;

interface Props {
	api: TravelRoutesAPI;
	defaultTravelRoute: TravelRoute;
}

interface Result {
	travelRoutes: TravelRoute[];
	travelRoute: TravelRoute;
	isLoading: boolean;
	error?: Error;
}

export const useTravelRoutesUseCases: UseCases<Props, Result> = ({
	api, defaultTravelRoute,
}) => ({
	...api,
	travelRoutes: api.data,
	travelRoute: api.data?.[0] ?? defaultTravelRoute,
});
