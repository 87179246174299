import { Colors } from "style/colors";
import { Item } from "style/global";
import styled from "styled-components";

interface SidebarItemStyleProps {
	highlighted: boolean;
	statusColor?: string;
}

export const SidebarItemStyle = styled(Item)<SidebarItemStyleProps>`
	${(props) => props.highlighted && `
		background-color: ${Colors.primaryLight};
	`}

	margin-bottom: 20px;
	border-left: 5px solid;
	border-left-color: ${({ statusColor }) => statusColor ?? "white"};
`;

export const BottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
