import { UIComponent, UILayout } from "@aptus/frontend-core";
import { LoadingButton } from "@mui/lab";
import { Stack, TextField, Typography } from "@mui/material";
import i18next from "i18next";
import { LoginFormLayoutProps, LoginFormProps, useLoginForm } from "./useLoginForm";

const Layout: UILayout<LoginFormLayoutProps> = ({
	submit, register, isLoading, loginError, errors, isValid,
}) => (
	<form onSubmit={submit}>
		<Stack>
			<TextField
				{...register("username")}
				label={i18next.t("loginForm.username")}
				type="text"
				error={!!errors.username}
				helperText={errors.username?.message}
			/>
			<TextField
				{...register("password")}
				label={i18next.t("loginForm.password")}
				type="password"
				error={!!errors.password}
				helperText={errors.password?.message}
			/>
			<Typography variant="body2" color="error">{loginError}</Typography>
			<LoadingButton type="submit" loading={isLoading} disabled={!isValid}>{i18next.t("loginForm.login")}</LoadingButton>
		</Stack>
	</form>
);

export const LoginForm: UIComponent<LoginFormProps> = (props) => (
	<Layout {...props} {...useLoginForm(props)} />
);
