/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTrucksAndInstallersVariablesDTO = Types.Exact<{
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
}>;


export type GetTrucksAndInstallersDTO = { __typename?: 'Query', interventions: Array<{ __typename?: 'Intervention', id: string, travelRoute: { __typename?: 'TravelRoute', truckId: string } }>, users: Array<{ __typename?: 'User', id: string, userName: string }> };


export const GetTrucksAndInstallersDocumentDTO = gql`
    query getTrucksAndInstallers($startDate: DateTime, $endDate: DateTime) {
  interventions(filter: {interventionDate: {gte: $startDate, lte: $endDate}}) {
    id
    travelRoute {
      truckId
    }
  }
  users {
    id
    userName
  }
}
    `;

/**
 * __useGetTrucksAndInstallers__
 *
 * To run a query within a React component, call `useGetTrucksAndInstallers` and pass it any options that fit your needs.
 * When your component renders, `useGetTrucksAndInstallers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrucksAndInstallers({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetTrucksAndInstallers(baseOptions?: Apollo.QueryHookOptions<GetTrucksAndInstallersDTO, GetTrucksAndInstallersVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrucksAndInstallersDTO, GetTrucksAndInstallersVariablesDTO>(GetTrucksAndInstallersDocumentDTO, options);
      }
export function useGetTrucksAndInstallersLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetTrucksAndInstallersDTO, GetTrucksAndInstallersVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrucksAndInstallersDTO, GetTrucksAndInstallersVariablesDTO>(GetTrucksAndInstallersDocumentDTO, options);
        }
export type GetTrucksAndInstallersHookResult = ReturnType<typeof useGetTrucksAndInstallers>;
export type GetTrucksAndInstallersLazyQueryDTOHookResult = ReturnType<typeof useGetTrucksAndInstallersLazyQueryDTO>;
export type GetTrucksAndInstallersQueryResultDTO = Apollo.QueryResult<GetTrucksAndInstallersDTO, GetTrucksAndInstallersVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    