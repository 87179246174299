import { PageComponent } from "@aptus/frontend-core";
import { useInterventions, useRoutes } from "domains";
import logo from "assets/images/logo-dark.svg";
import {
	Box, Divider, Typography, Stack,
} from "@mui/material";
import i18next from "i18next";
import { format } from "date-fns";
import { useGetInterventionPdfDetails } from "./models/getInterventionPdfDetails";
import { InformationBlock } from "./components/informationBlock";
import { ArticlesTable } from "./components/articlesTable";

export interface PdfPageProps {
	token?: string;
}

export const PdfPage: PageComponent<PdfPageProps> = ({ token }) => {
	const { params } = useRoutes();

	const { intervention } = useInterventions(useGetInterventionPdfDetails({
		variables: { id: params.id || "" },
		context: { headers: { authorization: `Bearer ${token}` } },
		skip: !token,
	}));

	return (
		<Box sx={{ m: 4, width: "210mm" }}>
			<img src={logo} alt="Detremmerie" />

			<Divider sx={{ my: 2 }} />

			<Typography variant="h1">{intervention.customer.name}</Typography>
			<Typography variant="body2">{intervention.customer.address.addressLine}</Typography>
			<Typography variant="body2">{`${intervention.customer.address.postalCode} ${intervention.customer.address.city}`.trim()}</Typography>
			<br />
			<Typography variant="body1">{intervention.customer.phoneNumber}</Typography>

			<Divider sx={{ my: 2 }} />

			<Stack direction="row">
				<InformationBlock
					width="50%"
					title={i18next.t("interventionDetails")}
					information={{
						[i18next.t("orderNumber")]: intervention.orderNumber,
						[i18next.t("interventionNumber")]: intervention.interventionNumber,
						[i18next.t("warehouse")]: intervention.warehouse,
						[i18next.t("dealerId")]: intervention.dealer.id,
						[i18next.t("dealerName")]: intervention.dealer.name,
						[i18next.t("reference")]: intervention.reference,
						[i18next.t("comment")]: intervention.comment,
					}}
				/>
				<InformationBlock
					width="50%"
					title={i18next.t("assemblyCrew")}
					information={{
						[i18next.t("truck")]: intervention.serviceReport?.licensePlate,
						[i18next.t("startMileage")]: intervention.serviceReport?.mileage.start,
						[i18next.t("endMileage")]: intervention.serviceReport?.mileage.end,
						[i18next.t("installers")]: intervention.installers.map((installer) => installer.name).join(", "),
					}}
				/>
			</Stack>
			<Stack direction="row">
				<Box width="50%">
					<Typography variant="h2">{i18next.t("executedWork")}</Typography>
					<Typography variant="body1">{`${format(intervention.date, "dd/MM/yyyy")} - ${format(intervention.start, "HH:mm")} - ${format(intervention.end, "HH:mm")}`}</Typography>
					<Typography variant="body1">{intervention.serviceReport?.executedWork}</Typography>
				</Box>
				<InformationBlock
					width="50%"
					title={i18next.t("remainingWork")}
					description={intervention.serviceReport?.remainingWork.description}
					information={{
						[i18next.t("manPower")]: intervention.serviceReport?.remainingWork.manPower || 0,
					}}
				/>
			</Stack>
			<Stack direction="row">
				<Box width="50%">
					<Typography variant="h2">{i18next.t("articlesRetoured")}</Typography>
					<Typography variant="body1">{intervention.serviceReport?.articlesRetoured}</Typography>
				</Box>
				<Box width="50%">
					<Typography variant="h2">{i18next.t("articlesToOrder")}</Typography>
					<Typography variant="body1">{intervention.serviceReport?.articlesToOrder}</Typography>
				</Box>
			</Stack>

			<Typography variant="h2">{i18next.t("articles")}</Typography>
			<ArticlesTable articles={intervention.articles} />

			<InformationBlock
				information={{
					[i18next.t("model")]: intervention.model,
					[i18next.t("colors")]: intervention.articles
						.map(({ description }) => description)
						.join("\n"),
				}}
			/>

			<Typography variant="h2">{i18next.t("commentsFromCustomer")}</Typography>
			{intervention.serviceReport?.commentFromCustomer
				? <Typography variant="body1">{intervention.serviceReport.commentFromCustomer}</Typography>
				: <Typography variant="body2">{i18next.t("noComments")}</Typography>}
			<Typography variant="h2">{i18next.t("commentForCustomer")}</Typography>
			{intervention.serviceReport?.commentForCustomer
				? <Typography variant="body1">{intervention.serviceReport.commentForCustomer}</Typography>
				: <Typography variant="body2">{i18next.t("noComments")}</Typography>}

			<br />
			<br />
			<Typography variant="body1">{i18next.t("signed")}</Typography>
			<Typography variant="body1">{intervention.customer.name}</Typography>
			<img src={intervention.serviceReport?.signature} alt={i18next.t("signatureFrom", { customer: intervention.customer.name })} />
		</Box>
	);
};
