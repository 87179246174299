import { UILogic } from "@aptus/frontend-core";
import { SaveServiceReportInput } from "hooks/serviceReports/models/serviceReportInput";
import { FormEvent, useState } from "react";
import {
	FieldPath, UseFormRegisterReturn, FieldErrors, useForm, useFieldArray,
} from "react-hook-form";
import { HTMLValidationSchema, toFormHookSchema } from "utils/validate";

interface Props {
	initialValues: SaveServiceReportInput;
	schema: HTMLValidationSchema<SaveServiceReportInput>;
	pdfUrl?: string;
	isLoading?: boolean;
	submit: (input: SaveServiceReportInput) => void;
}

interface Result {
	submit: (e: FormEvent<HTMLFormElement>) => void;
	register: (field: FieldPath<SaveServiceReportInput>) => UseFormRegisterReturn;
	errors: FieldErrors<SaveServiceReportInput>;
	fields: SaveServiceReportInput["internalRemarks"];
	isEditable: boolean;
	edit: () => void;
	cancel: () => void;
	internalRemarks: SaveServiceReportInput["internalRemarks"];
}

export type ServiceReportFormProps = Props;
export type EditableServiceReportFormLayoutProps = Omit<Result, "isEditable" | "edit"> & Pick<Props, "isLoading">;
export type ReadonlyServiceReportFormLayoutProps = Pick<Result, "internalRemarks" | "edit"> & Pick<Props, "pdfUrl" | "isLoading">;

export const useServiceReportForm: UILogic<Props, Result> = ({ initialValues, schema, submit: _submit }) => {
	const {
		handleSubmit, register, formState, control,
	} = useForm<SaveServiceReportInput>({ mode: "onChange", defaultValues: initialValues });
	const { fields, append } = useFieldArray<SaveServiceReportInput>({ control, name: "internalRemarks" });
	const [isEditable, setIsEditable] = useState<Result["isEditable"]>(false);

	const submit = (input: SaveServiceReportInput): void => {
		_submit(input);
		setIsEditable(false);
	};

	const edit: Result["edit"] = () => {
		if (fields.length === 0) append({});
		setIsEditable(true);
	};

	return {
		submit: handleSubmit(submit),
		register: (field) => register(field, (toFormHookSchema(schema) as any)[field]),
		errors: formState.errors,
		fields: fields as unknown as SaveServiceReportInput["internalRemarks"],
		isEditable,
		edit,
		cancel: () => setIsEditable(false),
		internalRemarks: initialValues.internalRemarks,
	};
};
