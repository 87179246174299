import { API, UseCases } from "@aptus/frontend-core";
import { Installer } from "./models/installer";

export type InstallersAPI = API<Installer[]>;

interface Props {
	api: InstallersAPI;
	defaultInstaller: Installer;
}

interface Result {
	installers: Installer[];
	installer: Installer;
	isLoading: boolean;
	error?: Error;
}

export const useInstallersUseCases: UseCases<Props, Result> = ({
	api, defaultInstaller,
}) => ({
	...api,
	installers: api.data,
	installer: api.data?.[0] ?? defaultInstaller,
});
