import { UIComponent, UILayout } from "@aptus/frontend-core";
import { Download } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import i18next from "i18next";
import { ButtonContainer } from "style/global";
import {
	EditableServiceReportFormLayoutProps, ReadonlyServiceReportFormLayoutProps, ServiceReportFormProps, useServiceReportForm,
} from "./useServiceReportForm";

const EditableLayout: UILayout<EditableServiceReportFormLayoutProps> = ({
	submit, register, errors, fields, cancel, isLoading,
}) => (
	<form onSubmit={submit} id="save-service-form">
		{fields.map((field, index) => (
			<TextField
				key={field.id}
				{...register(`internalRemarks.${index}.message`)}
				label={i18next.t("serviceReportForm.remarksForInternal")}
				error={!!errors.internalRemarks?.[index]}
				style={{ width: "100%" }}
				helperText={errors.internalRemarks?.[index].message}
			/>
		))}
		<ButtonContainer>
			<Button variant="outlined" onClick={cancel} disabled={isLoading}>{i18next.t("cancel")}</Button>
			<Button type="submit" disabled={isLoading}>{i18next.t("save")}</Button>
		</ButtonContainer>
	</form>
);

const ReadonlyLayout: UILayout<ReadonlyServiceReportFormLayoutProps> = ({
	internalRemarks, edit, pdfUrl, isLoading,
}) => (
	<>
		{internalRemarks.length > 0
			? <>{internalRemarks.map(({ message }) => <p>{message}</p>)}</>
			: <p>{i18next.t("detail.noRemark")}</p>}
		<ButtonContainer>
			<Button variant="outlined" onClick={edit} disabled={isLoading}>
				{i18next.t(internalRemarks.length === 0 ? "detail.addRemark" : "detail.updateRemark")}
			</Button>
			{pdfUrl && <a className="download-btn" target="_blank" href={pdfUrl} download rel="noreferrer"><Button startIcon={<Download />}>{i18next.t("detail.downloadPdf")}</Button></a>}
		</ButtonContainer>
	</>
);

export const ServiceReportForm: UIComponent<ServiceReportFormProps> = (props) => {
	const { isEditable, ...rest } = useServiceReportForm(props);

	if (isEditable) return <EditableLayout {...props} {...rest} />;
	return <ReadonlyLayout {...props} {...rest} />;
};
