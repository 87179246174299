import styled from "styled-components";

export const LoginPageStyle = styled.div`
	min-height: 100vh;

	display: grid;
	grid-template-rows: 100px 1fr;
`;

export const LoginFormStyle = styled.div`
	align-self: center;
	justify-self: center;

	width: 100%;
	max-width: 400px;
`;
