import { createContext } from "react";

export enum Order {
	Asc = "asc",
	Desc = "desc",
}

export interface Sort {
	order: Order;
	orderBy: string;
}

interface Context {
	sort: Sort;
	setSort: (name: string) => void;
}

const getNextSort = (sort: Sort): Sort => {
	switch (sort.order) {
		case Order.Asc:
			return { ...sort, order: Order.Desc };
		case Order.Desc:
			return { orderBy: "", order: Order.Asc };
		default:
			return { orderBy: "", order: Order.Asc };
	}
};

export const sortReducer = (sort: Sort, name: string) => {
	if (sort.orderBy === name) return getNextSort(sort);
	return { orderBy: name, order: Order.Asc };
};

export const SortContext = createContext<Context>({
	sort: { orderBy: "", order: Order.Asc },
	setSort: () => { },
});
