import styled from "styled-components";
import { Layout } from "style/layout";
import { Colors } from "style/colors";

export const OverviewPageStyle = styled(Layout)`
	background-color: ${Colors.background};
	display: flex;
	flex: 1;
`;

export const OverviewPageContentStyle = styled.section`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 20px 50px;
`;
