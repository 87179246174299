import { UIComponent } from "@aptus/frontend-core";
import { endOfWeek, format, startOfWeek } from "date-fns";
import { nlBE } from "date-fns/locale";
import { TravelRoute } from "hooks/travelRoutes/models/travelRoute";
import { SkeletonLoader } from "components/skeletonLoader";
import { Link } from "react-router-dom";
import { useRoutes } from "domains";
import { Intervention } from "hooks/interventions/models/intervention";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import i18next from "i18next";
import { IconButton } from "@mui/material";
import {
	SideBarGroup,
	SideBarGroupRow,
	SidebarHeader,
	SidebarStyle,
	TravelRouteTitle,
} from "./SidebarStyle";
import { SidebarItem, WithActive } from "../sidebarItem/sidebarItem";

interface SidebarProps {
	date?: Date;
	isLoading?: boolean;
	travelRoutes: TravelRoute[];
	href: string;
}

export const Sidebar: UIComponent<SidebarProps> = ({
	date = new Date(), isLoading, travelRoutes, href,
}) => {
	const { params } = useRoutes();

	const toInterventionWithIsActive = (_intervention: Intervention): WithActive<Intervention> => ({
		..._intervention,
		isActive: _intervention.id === params.id,
	});

	const checkItems = (calendarItems: TravelRoute[]) => calendarItems.every((item: TravelRoute) => item.interventions.length === 0);

	return (
		<SidebarStyle>
			<IconButton to={href} component={Link} aria-label={i18next.t("goBack")}>
				<ArrowBackIcon />
			</IconButton>
			<SidebarHeader>
				<h3>
					{format(startOfWeek(date), "dd MMM", { locale: nlBE })}
					{" - "}
					{format(endOfWeek(date), "dd MMM yyyy", { locale: nlBE })}
				</h3>
				<p>{format(date, "eeee", { locale: nlBE })}</p>
			</SidebarHeader>

			<div style={{ overflowY: "scroll", height: "calc(100vh - 307px)" }}>
				<SkeletonLoader
					isLoading={isLoading}
					skeleton={<SidebarItem isLoading />}
					amount={3}
					isEmpty={checkItems(travelRoutes)}
				>
					{travelRoutes.map((item) => {
						if (item.interventions.length === 0) {
							return <div />;
						}

						const intCode = item.interventions?.[0]?.interventionNumber;
						const licensePlate = item.interventions?.[0]?.serviceReport?.licensePlate;
						const {
							start,
							startKm,
							end,
							endKm,
						} = item.workingHour;
						return (
							<SideBarGroup key={item.id}>
								<SideBarGroupRow>
									<TravelRouteTitle>{i18next.t("calendarCard.intNumber")}</TravelRouteTitle>
									<p style={{ fontWeight: "bold" }}>{intCode}</p>
								</SideBarGroupRow>
								{licensePlate && (
									<SideBarGroupRow>
										<TravelRouteTitle>{i18next.t("calendarCard.car")}</TravelRouteTitle>
										<p>{licensePlate}</p>
									</SideBarGroupRow>
								)}
								{start && (
									<SideBarGroupRow>
										<TravelRouteTitle>{i18next.t("calendarCard.startTime")}</TravelRouteTitle>
										<p>{format(new Date(start), "HH:mm", { locale: nlBE })}</p>
									</SideBarGroupRow>
								)}
								{start && (startKm !== undefined || startKm !== null) && (
									<SideBarGroupRow>
										<TravelRouteTitle>{i18next.t("calendarCard.startKm")}</TravelRouteTitle>
										<p>{startKm}</p>
									</SideBarGroupRow>
								)}
								{end && (
									<SideBarGroupRow>
										<TravelRouteTitle>{i18next.t("calendarCard.endTime")}</TravelRouteTitle>
										<p>{format(new Date(end), "HH:mm", { locale: nlBE })}</p>
									</SideBarGroupRow>
								)}
								{end && (endKm !== undefined || endKm !== null) && (
									<SideBarGroupRow>
										<TravelRouteTitle>{i18next.t("calendarCard.endKm")}</TravelRouteTitle>
										<p>{endKm}</p>
									</SideBarGroupRow>
								)}
								<div style={{ paddingTop: "20px" }}>
									{item.interventions.map(toInterventionWithIsActive).map((value) => {
										const newURL = value.url.replace(":travelrouteid", item.id);
										const updateObject = { ...value, url: newURL };
										return (<SidebarItem key={value.id} {...updateObject} />);
									})}
								</div>
							</SideBarGroup>
						);
					})}
				</SkeletonLoader>
			</div>
		</SidebarStyle>
	);
};
