import { TableRow as MUITableRow } from "@mui/material";
import { UIComponent } from "@aptus/frontend-core";
import { Children, cloneElement, isValidElement } from "react";
import { Link } from "react-router-dom";

interface RowProps {
	row: any;
	index: number;
}

export const TableRow: UIComponent<RowProps> = ({ row, index, children }) => {
	const cells = Children.toArray(children).map((cell) => {
		if (isValidElement(cell)) {
			return cloneElement(cell, { row, index });
		}

		return cell;
	});

	return (
		<MUITableRow
			component={row.url ? Link : "td"}
			to={row.url}
			hover={!!row.url}
		>
			{cells}
		</MUITableRow>
	);
};
