/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateServiceReportVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  remarksForInternal?: Types.InputMaybe<Array<Types.RemarkInputDTO> | Types.RemarkInputDTO>;
}>;


export type UpdateServiceReportDTO = { __typename?: 'Mutation', updateOneServiceReport: { __typename?: 'ServiceReport', id: string } };


export const UpdateServiceReportDocumentDTO = gql`
    mutation updateServiceReport($id: String!, $remarksForInternal: [RemarkInput!]) {
  updateOneServiceReport(id: $id, data: {remarksForInternal: $remarksForInternal}) {
    id
  }
}
    `;
export type UpdateServiceReportMutationFnDTO = Apollo.MutationFunction<UpdateServiceReportDTO, UpdateServiceReportVariablesDTO>;

/**
 * __useUpdateServiceReport__
 *
 * To run a mutation, you first call `useUpdateServiceReport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceReport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceReport, { data, loading, error }] = useUpdateServiceReport({
 *   variables: {
 *      id: // value for 'id'
 *      remarksForInternal: // value for 'remarksForInternal'
 *   },
 * });
 */
export function useUpdateServiceReport(baseOptions?: Apollo.MutationHookOptions<UpdateServiceReportDTO, UpdateServiceReportVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceReportDTO, UpdateServiceReportVariablesDTO>(UpdateServiceReportDocumentDTO, options);
      }
export type UpdateServiceReportHookResult = ReturnType<typeof useUpdateServiceReport>;
export type UpdateServiceReportMutationResultDTO = Apollo.MutationResult<UpdateServiceReportDTO>;
export type UpdateServiceReportMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateServiceReportDTO, UpdateServiceReportVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    