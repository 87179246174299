/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInterventionsVariablesDTO = Types.Exact<{
  filter?: Types.InputMaybe<Types.InterventionFilterDTO>;
}>;

export type GetInterventionsDTO = {
  __typename?: 'Query';
  interventions: Array<{
    __typename?: 'Intervention';
    id: string;
    interventionDate: any;
    interventionCode: any;
    customerAddress: {
      __typename?: 'InterventionCustomerAddress';
      name: string;
      phoneNumber?: string | null;
      city?: string | null;
      postalcode?: string | null;
      street?: string | null;
    };
  }>;
};

export const GetInterventionsDocumentDTO = gql`
  query getInterventions($filter: InterventionFilter) {
    interventions(
      filter: $filter
      sort: [{ field: "interventionDate", order:  DESC }]
    ) {
      id
      interventionDate
      interventionCode
      customerAddress {
        name
        phoneNumber
        city
        postalcode
        street
      }
      serviceReport {
        vehicleIdentifier
        startKm
        endKm
        completed
        remainingWork
        remainingWorkManPower
        workingHours {
          start
          end
        }
      }
	  status
      travelRoute {
        id
        employees {
          id
          userName
        }
        workingHour {
          start
          startKm
          end
          endKm
        }
      }
    }
  }
`;

/**
 * __useGetInterventions__
 *
 * To run a query within a React component, call `useGetInterventions` and pass it any options that fit your needs.
 * When your component renders, `useGetInterventions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterventions({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetInterventions(
  baseOptions?: Apollo.QueryHookOptions<
    GetInterventionsDTO,
    GetInterventionsVariablesDTO
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInterventionsDTO, GetInterventionsVariablesDTO>(
    GetInterventionsDocumentDTO,
    options
  );
}
export function useGetInterventionsLazyQueryDTO(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInterventionsDTO,
    GetInterventionsVariablesDTO
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInterventionsDTO, GetInterventionsVariablesDTO>(
    GetInterventionsDocumentDTO,
    options
  );
}
export type GetInterventionsHookResult = ReturnType<typeof useGetInterventions>;
export type GetInterventionsLazyQueryDTOHookResult = ReturnType<
  typeof useGetInterventionsLazyQueryDTO
>;
export type GetInterventionsQueryResultDTO = Apollo.QueryResult<
  GetInterventionsDTO,
  GetInterventionsVariablesDTO
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
