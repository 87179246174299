// This is a strictly typed version of the default NodeJS EventEmitter.
import { AuthenticationEvents } from "hooks/authentication/useAuthenticationUseCases";
import { StrictEventEmitter } from "strict-event-emitter";

export interface BaseEvents {
	mutationSucceeded: (message: string) => void;
	mutationFailed: (error: Error) => void;
}

type Events = BaseEvents & AuthenticationEvents;

/**
 * Allows to emit events and listen to them. This allows us to chain different Interaction Domains
 * together without explicitly depending on each other.
 */
export const event = new StrictEventEmitter<Events>();
