/* eslint-disable react/no-array-index-key */
import { UIComponent } from "@aptus/frontend-core";
import { pdfjs, Document, Page } from "react-pdf";
import { usePdfViewer } from "./hooks/usePdfViewer";
import { PdfViewerStyle } from "./PdfViewerStyle";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfViewerProps {
	url: string;
}

export const PdfViewer: UIComponent<PdfViewerProps> = ({ url }) => {
	const { onLoadSuccess, pages } = usePdfViewer({});

	if (!url) {
		return null;
	}

	return (
		<PdfViewerStyle>
			<Document onLoadSuccess={onLoadSuccess} file={url}>
				{pages.map((_, i) => <Page key={i} pageNumber={i + 1} />)}
			</Document>
		</PdfViewerStyle>
	);
};
