import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckIcon from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import { ReactNode } from "react";

export const getStatusColor = (status?: string): string => {
	switch (status) {
		case "completed":
			return "#08E28B";
		case "completedWithRemainingWork":
			return "#FF4747";
		case "inProgress":
		case "planned":
		default:
			return "#979797";
	}
};

export const showStatusIcon = (status?: string): ReactNode => {
	switch (status) {
		case "completed":
			return <CheckIcon style={{ color: "#08E28B" }} />;
		case "completedWithRemainingWork":
			return <CheckIcon style={{ color: "#FF4747" }} />;
		case "inProgress":
			return <SettingsIcon style={{ color: "#979797" }} />;
		case "planned":
		default:
			return <CalendarTodayIcon style={{ color: "#979797" }} />;
	}
};
