import nlTranslations from "assets/translations/nl.json";
import { format as dateFnsFormat } from "date-fns";
import _i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { nl } from "date-fns/locale";

const FALLBACK_LANGUAGE = "nl";

export const i18n = _i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.use(initReactI18next)
	.init({
		debug: true,
		fallbackLng: FALLBACK_LANGUAGE,
		interpolation: {
			escapeValue: false,
		},
		resources: {
			// WARNING: useRequests.setFilter can't handle multiple languages. Fix that function before adding more languages!
			nl: {
				translation: nlTranslations,
			},
		},
	});

/* https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb */

const locales = { nl };
export const format = (date: Date, formatStr: string) => (
	dateFnsFormat(date, formatStr, {
		locale: locales[navigator.language as keyof typeof locales || FALLBACK_LANGUAGE],
	})
);
