import { UIComponent } from "@aptus/frontend-core";
import { Header } from "pages/header/Header";
import { Private } from "components/private";
import { useAuthentication, useRoutes } from "domains";
// import { Role } from "hooks/authentication/models/profile";
import { useRoutesEvents } from "hooks/routes/useRoutesUseCases";
import { DetailPage } from "pages/detailPage/DetailPage";
import { LoginPage } from "pages/loginPage/loginPage";
import { OverviewPage } from "pages/overviewPage/OverviewPage";
import { Routes, Route, Navigate } from "react-router-dom";
import { PdfPage } from "pages/pdf/PdfPage";
import { useAuthenticationEvents } from "hooks/authentication/useAuthenticationUseCases";

/**
 * This is the router of the application.
 * It handles everything that is based on the "Router state" (URL).
 * This enforces consistency on how URLs are handled throughout the application.
 */

export const Content: UIComponent = () => {
	const { baseRoutes, getQuery } = useRoutes();
	const { profile } = useAuthentication();
	useRoutesEvents(useRoutes());
	useAuthenticationEvents(useAuthentication());

	return (
		<Routes>
			<Route path="*" element={<Navigate to={baseRoutes.overview} replace />} />
			<Route path={baseRoutes.login} element={<LoginPage />} />
			<Route element={<Header />}>
				<Route
					path={baseRoutes.overview}
					element={(
						<Private isAuthorized={!profile.hasRole()} redirectTo={baseRoutes.login}>
							<OverviewPage />
						</Private>
					)}
				/>
				<Route
					path={baseRoutes.detail}
					element={(
						<Private isAuthorized={!profile.hasRole()} redirectTo={baseRoutes.login}>
							<DetailPage />
						</Private>
					)}
				/>
			</Route>
			<Route
				path={baseRoutes.pdf}
				element={(
					<Private isAuthorized={!!getQuery("jwt")} redirectTo={baseRoutes.overview}>
						<PdfPage token={getQuery("jwt")} />
					</Private>
				)}
			/>
		</Routes>
	);
};
