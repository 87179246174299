import { DeepPartial, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { Installer } from "hooks/installers/models/installer";
import { ServiceReport } from "hooks/serviceReports/models/serviceReport";
import i18next from "i18next";
import {
	InterventionArticleDTO,
	InterventionCustomerAddressDTO,
	InterventionDTO,
	InterventionStatusDTO,
	QueryDTO,
	ServiceReportDTO,
	UserDTO,
} from "models/schema";
import {
	Address, Article, Customer, Dealer, Intervention,
} from "./models/intervention";
import { InterventionsAPI } from "./useInterventionsUseCases";

export type InterventionsAPIDTO = ApolloAPI<QueryDTO, "interventions" | "intervention">;

interface Props {
	toServiceReport: ToInternal<DeepPartial<ServiceReportDTO>, ServiceReport>;
	toInstaller: ToInternal<DeepPartial<UserDTO>, Installer>;
	createURL: (id: Intervention["id"]) => string;
}

interface Mapper {
	toAPI: ToAPI<InterventionsAPIDTO, InterventionsAPI>;
	toIntervention: ToInternal<DeepPartial<InterventionDTO>, Intervention>;
}

export class InterventionMapper implements Mapper {
	private toServiceReport: Props["toServiceReport"];

	private toInstaller: Props["toInstaller"];

	private createURL: Props["createURL"];

	constructor(props: Props) {
		this.toServiceReport = props.toServiceReport;
		this.toInstaller = props.toInstaller;
		this.createURL = props.createURL;
	}

	private toAddress: ToInternal<DeepPartial<InterventionCustomerAddressDTO>, Address> = (address) => ({
		city: address.city || "",
		postalCode: address.postalcode || "",
		addressLine: address.street || "",
	});

	private toCustomer: ToInternal<DeepPartial<InterventionDTO>, Customer> = (intervention) => ({
		name: intervention.customerAddress?.name || i18next.t("domain.intervention.noCustomerName"),
		address: this.toAddress(intervention.customerAddress || {}),
		phoneNumber: intervention.customerAddress?.phoneNumber || i18next.t("domain.intervention.noCustomerPhoneNumber"),
		referenceNumber: intervention.reference || i18next.t("domain.intervention.noCustomerReferenceNumber"),
	});

	private toDealer: ToInternal<DeepPartial<InterventionDTO>, Dealer> = (intervention) => ({
		id: intervention.dealerId || "",
		name: intervention.dealerName || i18next.t("domain.intervention.noDealer"),
	});

	private toArticle: ToInternal<DeepPartial<InterventionArticleDTO>, Article> = (article) => ({
		reference: article.reference || i18next.t("domain.intervention.noArticleReference"),
		colli: article.colliNumber || 0,
		comment: article.remarks || "",
		description: article.description || i18next.t("domain.intervention.noArticleDescription"),
		amount: article.quantity || 0,
	});

	public toIntervention: Mapper["toIntervention"] = (intervention) => ({
		id: intervention.id || "",
		url: this.createURL(intervention.id || ""),
		date: intervention.interventionDate ? new Date(intervention.interventionDate) : new Date(),
		customer: this.toCustomer(intervention),
		dealer: this.toDealer(intervention),
		serviceReport: intervention.serviceReport ? this.toServiceReport(intervention.serviceReport) : undefined,
		truck: intervention.travelRoute?.truckId || "domain.intervention.noTruck",
		interventionNumber: intervention.interventionCode || i18next.t("domain.intervention.noInterventionNumber"),
		comment: intervention.remarks || "",
		reference: intervention.reference || i18next.t("domain.intervention.noReference"),
		orderNumber: intervention.orderNumber || i18next.t("domain.intervention.noOrderNumber"),
		warehouse: intervention.warehouseLocation || i18next.t("domain.intervention.noWarehouse"),
		articles: (intervention.articles || []).map(this.toArticle),
		model: intervention.model || i18next.t("domain.intervention.noModel"),
		start: (intervention.serviceReport && this.toServiceReport(intervention.serviceReport).workingHours[0]?.from) || new Date(),
		end: (intervention.serviceReport && this.toServiceReport(intervention.serviceReport).workingHours.at(-1)?.to) || new Date(),
		installers: (intervention.travelRoute?.employees || []).map(this.toInstaller),
		travelRouteId: intervention.travelRoute?.id ?? "",
		status: intervention.status ?? "planned" as InterventionStatusDTO,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: InterventionsAPIDTO["data"]): DeepPartial<InterventionDTO>[] => {
			if (data?.interventions) return data.interventions || [];
			if (data?.intervention) return [data.intervention];
			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toIntervention),
		};
	};
}
