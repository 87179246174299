import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { isBefore } from "date-fns";
import { event } from "event";
import { createUploadLink } from "apollo-upload-client";
import jwtDecode from "jwt-decode";

const cache = new InMemoryCache();

const uploadLink = createUploadLink({
	uri: process.env.REACT_APP_API_URL || window?.appEnvironment?.API_URL,
	fetch: (...args) => fetch(...args),
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem("accessToken");
	const SECOND = 1000;

	if (token) {
		const { exp } = jwtDecode<{ exp: number }>(token);
		const isExpired: boolean = isBefore(new Date(exp * SECOND), new Date());
		if (isExpired) event.emit("tokenExpired");
	}

	return {
		headers: {
			...headers,
			...token ? { authorization: `Bearer ${token}` } : {},
		},
	};
});

export const client = new ApolloClient({ cache, link: from([authLink, uploadLink]) });
