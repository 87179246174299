import { PageComponent } from "@aptus/frontend-core";
import { Button } from "@mui/material";
import { FilterBar, Item, Status } from "components/filterBar/FilterBar";
import i18next from "i18next";
import Logo from "assets/images/logo.svg";
import {
	useAuthentication, useInstallers, useInterventions, useRoutes,
} from "domains";
import { Link, Outlet } from "react-router-dom";
import { startOfWeek, endOfWeek } from "date-fns";
import { Installer } from "hooks/installers/models/installer";
import {
	HeaderBarStyle, HeaderContentStyle, HeaderStyle,
} from "./HeaderStyle";
import { useGetTrucksAndInstallers } from "./models/getTrucksAndInstallers";

interface HeaderProps {
	noFilter?: boolean;
	noLogout?: boolean;
}

export const Header: PageComponent<HeaderProps> = ({ noFilter, noLogout }) => {
	const { routes, setQuery, getQuery } = useRoutes();
	const { logout } = useAuthentication();
	const api = useGetTrucksAndInstallers({
		variables: {
			startDate: getQuery("start") || startOfWeek(new Date()).toISOString(),
			endDate: getQuery("end") || endOfWeek(new Date()).toISOString(),
		},
	});
	const { trucks } = useInterventions(api);
	const { installers } = useInstallers(api);

	const toInstallerItem = (installer: Installer): Item => ({
		label: installer.name,
		value: installer.id,
	});

	const toTruckItem = (truck: string): Item => ({
		label: truck,
		value: truck,
	});

	return (
		<>
			<HeaderStyle>
				<HeaderContentStyle>
					<HeaderBarStyle>
						<Link to={routes.overview}>
							<img src={Logo} alt={i18next.t("header.title")} />
						</Link>
						{!noLogout && (
							<Button onClick={logout} variant="text">
								{i18next.t("header.logout")}
							</Button>
						)}
					</HeaderBarStyle>
					{!noFilter && (
						<FilterBar
							search={getQuery("search")}
							installer={getQuery("installer")}
							installers={installers.map(toInstallerItem)}
							truck={getQuery("truck")}
							trucks={trucks.map(toTruckItem)}
							status={getQuery("status") as Status}
							setSearch={(search) => setQuery({ search })}
							setInstaller={(installer) => setQuery({ installer })}
							setTruck={(truck) => setQuery({ truck })}
							setStatus={(status) => setQuery({ status })}
						/>
					)}
				</HeaderContentStyle>
			</HeaderStyle>
			<Outlet />
		</>
	);
};
