import { API, Mutation, UseCases } from "@aptus/frontend-core";
import { useState } from "react";
import { event } from "event";
import { HTMLValidationSchema } from "utils/validate";
import i18next from "i18next";
import { ServiceReport } from "./models/serviceReport";
import { SaveServiceReportInput, UpdateServiceReportInput } from "./models/serviceReportInput";

export type ServiceReportsAPI = API<ServiceReport[]>;

export type UpdateServiceReportMutation = Mutation<UpdateServiceReportInput, ServiceReport>;

interface Props {
	api: ServiceReportsAPI;
	defaultServiceReport: ServiceReport;
	updateMutation: UpdateServiceReportMutation;
}

interface Result {
	serviceReport: ServiceReport;
	isLoading: boolean;
	error?: Error;
	saveSchema: HTMLValidationSchema<SaveServiceReportInput>;
	save: (input: SaveServiceReportInput) => Promise<void>;
}

export const useServiceReportsUseCases: UseCases<Props, Result> = ({
	api, defaultServiceReport, updateMutation,
}) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(api.isLoading);
	const isUpdate = (input: SaveServiceReportInput): input is UpdateServiceReportInput => !!input.id;

	const saveSchema: Result["saveSchema"] = {
		id: {},
		internalRemarks: [{
			message: { required: true },
		}],
	};

	const save: Result["save"] = async (input) => {
		try {
			setIsLoading(true);

			if (isUpdate(input)) {
				await updateMutation({ ...input });
				event.emit("mutationSucceeded", i18next.t("domain.serviceReport.saved"));
			}
		} catch (error) {
			if (error instanceof Error) {
				event.emit("mutationFailed", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return {
		serviceReport: api.data[0] || defaultServiceReport,
		isLoading,
		error: api.error,
		saveSchema,
		save,
	};
};
