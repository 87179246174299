import styled from "styled-components";

export const SidebarStyle = styled.div`
	padding-right: 20px;
	padding-top: 20px;
`;

export const SidebarHeader = styled.div`
	margin: 10px;
`;

export const SideBarGroup = styled.div`
	background-color: #F3F3F3;
	margin: 10px 0px;
	padding: 10px 20px;
	border-radius: 10px;
`;

export const SideBarGroupRow = styled.div`
	padding: 0px 10px;
	display: flex;
	justify-content: space-between;
`;

export const TravelRouteTitle = styled.p`
	color: grey
`;
