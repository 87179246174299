import { useMemo } from "react";
import { UIComponent } from "@aptus/frontend-core";
import { SkeletonLoader } from "components/skeletonLoader";
import { TravelRoute } from "hooks/travelRoutes/models/travelRoute";
import { randomDate } from "utils/randomDate";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import { format, getDay } from "date-fns";
import { nlBE } from "date-fns/locale";
import { CalendarHeaderItem, CalendarStyle } from "./CalendarStyle";
import { CalendarItem } from "./CalendarItem";
import { CalendarGroup } from "../CalendarGroup/CalendarGroup";

interface CalendarProps {
	isLoading?: boolean;
	items: TravelRoute[];
	dateRange: [string, string];
}

const groupByDate = (values: TravelRoute[]): { [code: string]: TravelRoute[] } => values.reduce((group: { [code: string]: TravelRoute[] }, item) => {
	const code = getDay(new Date(item.startingHour));
	const value = group[code];
	return ({ ...group, [getDay(new Date(item.startingHour))]: [...(value || []), item] });
}, {});

export const Calendar: UIComponent<CalendarProps> = ({
	isLoading,
	items,
	dateRange,
}) => {
	const groupedItems = useMemo(() => groupByDate(items), [items]);

	const headerDates: Date[] = eachDayOfInterval({
		start: new Date(dateRange[0]),
		end: new Date(dateRange[1]),
	});

	const checkItems = (calendarItems: TravelRoute[]) => calendarItems.every((item: TravelRoute) => item.interventions.length === 0);

	return (
		<CalendarStyle>
			{headerDates.map((item: Date) => (
				<CalendarHeaderItem key={item.toISOString()}>
					<p className="title">
						{format(item, "EEEE", { locale: nlBE })}
					</p>
					<p className="date">{format(item, "dd MMM yyyy", { locale: nlBE })}</p>
				</CalendarHeaderItem>
			))}
			<SkeletonLoader
				isLoading={isLoading}
				isEmpty={checkItems(items)}
				skeleton={(
					<>
						<CalendarItem isLoading date={randomDate()} />
						<CalendarItem isLoading date={randomDate()} />
						<CalendarItem isLoading date={randomDate()} />
						<CalendarItem isLoading date={randomDate()} />
						<CalendarItem isLoading date={randomDate()} />
						<CalendarItem isLoading date={randomDate()} />
						<CalendarItem isLoading date={randomDate()} />
					</>
				)}
				amount={2}
			>
				{Object.keys(groupByDate(items)).map((key: string) => <CalendarGroup key={parseInt(key, 10)} startDate={parseInt(key, 10)} item={groupedItems[key]} />)}
			</SkeletonLoader>
		</CalendarStyle>
	);
};
