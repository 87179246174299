import { UIComponent } from "@aptus/frontend-core";
import { Typography, Stack, Box } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

interface Props {
	title?: ReactNode;
	description?: ReactNode;
	information: Record<string, string | number>;
	width?: CSSProperties["width"];
}

export const InformationBlock: UIComponent<Props> = ({
	title, description, information, width,
}) => (
	<Box width={width}>
		<Typography variant="h2">{title}</Typography>
		<Typography variant="body1">{description}</Typography>
		{Object.entries(information).map(([key, value]) => (
			<Stack direction="row" key={key}>
				<Typography variant="body2" width="40%">{key}</Typography>
				<Typography variant="body1">{value}</Typography>
			</Stack>
		))}
	</Box>
);
