import { isNotEmpty } from "@aptus/frontend-core";
import { isAfter, isBefore } from "date-fns";
import { LoginDTO, LoginVariablesDTO } from "hooks/authentication/models/login";
import { RefreshTokenDTO, RefreshTokenVariablesDTO } from "hooks/authentication/models/refreshToken";
import { UpdateServiceReportDTO, UpdateServiceReportVariablesDTO } from "hooks/serviceReports/models/updateServiceReport";
import { RemarkInputDTO } from "models/schema";
import { graphql, RequestHandler } from "msw";
import { GetInterventionDetailDTO, GetInterventionDetailVariablesDTO } from "pages/detailPage/models/getInterventionDetail";
import { GetTrucksAndInstallersDTO, GetTrucksAndInstallersVariablesDTO } from "pages/header/models/getTrucksAndInstallers";
import { GetInterventionsDTO, GetInterventionsVariablesDTO } from "pages/overviewPage/models/getInterventions";
import { GetInterventionPdfDetailsDTO, GetInterventionPdfDetailsVariablesDTO } from "pages/pdf/models/getInterventionPdfDetails";

import { db } from "./db";

/**
 * Here we define what API request returns in our mock.
 *
 * Example: `[
 *    graphql.query('getAlbums', (req, res, ctx) => res(ctx.data({
 *       todos: MOCKED_TODOS
 *    })))
 * ]`
 */

const delay = process.env.NODE_ENV === "test" ? 0 : undefined;

export const handlers: RequestHandler[] = [
	graphql.mutation<LoginDTO, LoginVariablesDTO>("login", (req, res, ctx) => {
		if (req.variables.password === "invalid password") {
			return res(
				ctx.delay(delay),
				ctx.errors([{ message: "Login failed" }]),
			);
		}

		return res(
			ctx.delay(delay),
			ctx.data({
				login: {
					accessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJOaWNvbGFzIiwicm9sZXMiOlsiUk9MRV9TQ0hFRFVMRVIiLCJST0xFX1dFQlVTRVJTIl0sImlzcyI6Imh0dHBzOi8vYjJiLmRldHJlbW1lcmllLmJlL3VzZXJzZXJ2aWNlL2xvZ2luIiwiZXhwIjo5ODUzOTM3MzYyLCJ0b2tlblR5cGUiOiJhY2Nlc3MifQ.QMtbcA-bq8RXbQphQ6pK6dUt6Zv2j4rgiKdpE7DIqOs",
					refreshToken: "refreshToken",
				},
			}),
		);
	}),

	graphql.mutation<RefreshTokenDTO, RefreshTokenVariablesDTO>("refreshToken", (req, res, ctx) => {
		if (req.variables.refreshToken === "expiredRefreshToken") {
			return res(
				ctx.delay(delay),
				ctx.errors([{ message: "Refresh token expired" }]),
			);
		}

		return res(
			ctx.delay(delay),
			ctx.data({
				refreshToken: {
					accessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJOaWNvbGFzIiwicm9sZXMiOlsiUk9MRV9TQ0hFRFVMRVIiLCJST0xFX1dFQlVTRVJTIl0sImlzcyI6Imh0dHBzOi8vYjJiLmRldHJlbW1lcmllLmJlL3VzZXJzZXJ2aWNlL2xvZ2luIiwiZXhwIjo5ODUzOTM3MzYyLCJ0b2tlblR5cGUiOiJhY2Nlc3MifQ.QMtbcA-bq8RXbQphQ6pK6dUt6Zv2j4rgiKdpE7DIqOs",
					refreshToken: "refreshedRefreshToken",
				},
			}),
		);
	}),
	graphql.query<GetInterventionsDTO, GetInterventionsVariablesDTO>("getInterventions", (req, res, ctx) => {
		const interventions: any = db.intervention.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				interventions: interventions
					.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime())
					.filter((intervention: any) => (
						req.variables.filter?.interventionDate?.gte && isAfter(new Date(intervention.interventionDate), new Date(req.variables.filter?.interventionDate?.gte))
						&& req.variables.filter.interventionDate?.lte && isBefore(new Date(intervention.interventionDate), new Date(req.variables.filter?.interventionDate?.lte))
					))
					.filter(({ travelRoute }: any) => travelRoute.truckId.toString().includes(req.variables.filter?.travelRoute?.truckId?.equals || "")),
				/* TODO: fix mocks based on API filter */
				// .filter(({ customerAddress }: any) => (req.variables.search ? (
				// 	customerAddress.name.toString().includes(req.variables.search)
				// || customerAddress.street.toString().includes(req.variables.search)
				// || customerAddress.city.toString().includes(req.variables.search)
				// || customerAddress.postalcode.toString().includes(req.variables.search)
				// ) : true)),
			}),
		);
	}),
	graphql.query<GetInterventionDetailDTO, GetInterventionDetailVariablesDTO>("getInterventionDetail", (req, res, ctx) => {
		const intervention: any = db.intervention.findFirst({ where: { id: { equals: req.variables.id } } });

		return res(
			ctx.delay(delay),
			ctx.data({
				intervention,
			}),
		);
	}),
	graphql.query<GetInterventionPdfDetailsDTO, GetInterventionPdfDetailsVariablesDTO>("getInterventionPdfDetails", (req, res, ctx) => {
		const intervention: any = db.intervention.findFirst({ where: { id: { equals: req.variables.id } } });

		return res(
			ctx.delay(delay),
			ctx.data({
				intervention,
			}),
		);
	}),
	graphql.mutation<UpdateServiceReportDTO, UpdateServiceReportVariablesDTO>("updateServiceReport", (req, res, ctx) => {
		const toRemark = (remark: RemarkInputDTO) => {
			if (remark.id) {
				return db.remark.update({
					where: { id: { equals: remark.id } },
					data: { message: remark.message },
				});
			}

			return db.remark.create({ message: remark.message });
		};

		const remarkInputs = Array.isArray(req.variables.remarksForInternal) ? req.variables.remarksForInternal : [req.variables.remarksForInternal];
		const remarks = remarkInputs.filter(isNotEmpty).map(toRemark);

		const serviceReport: any = db.serviceReport.update({
			where: { id: { equals: req.variables.id } },
			data: { remarksForInternal: remarks },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				updateOneServiceReport: serviceReport,
			}),
		);
	}),
	graphql.query<GetTrucksAndInstallersDTO, GetTrucksAndInstallersVariablesDTO>("getTrucksAndInstallers", (req, res, ctx) => {
		const interventions: any = db.intervention.getAll();
		const users: any = db.user.getAll();

		return res(
			ctx.delay(delay),
			ctx.data({
				interventions: interventions
					.filter((intervention: any) => (
						req.variables.startDate && isAfter(new Date(intervention.interventionDate), new Date(req.variables.startDate))
						&& req.variables.endDate && isBefore(new Date(intervention.interventionDate), new Date(req.variables.endDate))
					)),
				users,
			}),
		);
	}),
];
