export interface DeduplicationUtils {
	primitives: <T>(arr: T[]) => T[];
	byProperty: <T, K extends keyof T>(arr: T[], property: K) => T[];
	byProperties: <T, K extends keyof T>(arr: T[], properties: K[]) => T[];
}

export class DeduplicationUtils implements DeduplicationUtils {
	public primitives: DeduplicationUtils["primitives"] = (arr) => (
		Array.from(new Set(arr))
	);

	public byProperty = <T, K extends keyof T>(arr: T[], property: K): T[] => {
		const isDuplicate = (a: T, index: number) => arr.findIndex((b) => a[property] === b[property]) === index;

		return arr.filter(isDuplicate);
	};

	public byProperties: DeduplicationUtils["byProperties"] = (arr, properties) => {
		const seen = Object.create({});

		const result = arr.filter((item) => {
			const key = properties.map((property) => item[property]).join("|");

			if (!seen[key]) {
				seen[key] = true;
				return true;
			}

			return false;
		});

		return result;
	};
}
