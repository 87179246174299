import { PageComponent } from "@aptus/frontend-core";
import { Header } from "pages/header/Header";
import { useAuthentication } from "domains";
import { LoginForm } from "./components/loginForm/loginForm";
import { LoginFormStyle, LoginPageStyle } from "./loginPageStyle";

export const LoginPage: PageComponent = () => {
	const {
		login, loginSchema, error, isLoading,
	} = useAuthentication();
	return (
		<LoginPageStyle>
			<Header noFilter noLogout />
			<LoginFormStyle>
				<LoginForm
					login={login}
					schema={loginSchema}
					loginError={error}
					isLoading={isLoading}
				/>
			</LoginFormStyle>
		</LoginPageStyle>
	);
};
