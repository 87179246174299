import { Colors } from "style/colors";
import { MOBILE_WIDTH } from "style/layout";
import styled from "styled-components";

export const HeaderStyle = styled.header`
	background-color: ${Colors.primary};
	padding: 30px;

	width: 100vw;
	display: grid;
	grid-template-columns: 1fr 1400px 1fr;

	@media (max-width: ${MOBILE_WIDTH}) {
		grid-template-columns: 8px 1fr 8px;
	}
`;

export const HeaderContentStyle = styled.div`
	grid-column: 2;
`;

export const HeaderBarStyle = styled.div`
	display: flex;
	justify-content: space-between;

	color: ${Colors.white};
`;
