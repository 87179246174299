import { UIComponent } from "@aptus/frontend-core";
import {
	DatePicker,
	LocalizationProvider, PickersDay, PickersDayProps,
} from "@mui/lab";
import React from "react";
import {
	endOfWeek, isSameDay, startOfWeek, isWithinInterval, format, addWeeks, subWeeks,
} from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { IconButton, styled, TextField } from "@mui/material";
import { nlBE } from "date-fns/locale";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import i18next from "i18next";
import { WeekPickerStyle } from "./WeekPickerStyle";

type CustomPickerDayProps = PickersDayProps<Date> & {
	dayIsBetween: boolean;
	isFirstDay: boolean;
	isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
	shouldForwardProp: (prop) => prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({
	theme, dayIsBetween, isFirstDay, isLastDay,
}) => ({
	...(dayIsBetween && {
		borderRadius: 0,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		"&:hover, &:focus": {
			backgroundColor: theme.palette.primary.dark,
		},
	}),
	...(isFirstDay && {
		borderTopLeftRadius: "50%",
		borderBottomLeftRadius: "50%",
	}),
	...(isLastDay && {
		borderTopRightRadius: "50%",
		borderBottomRightRadius: "50%",
	}),
})) as React.ComponentType<CustomPickerDayProps>;

interface WeekPickerProps {
	onChange: (start: Date | null, end: Date | null) => void;
	date?: string;
}

export const WeekPicker: UIComponent<WeekPickerProps> = ({ onChange, date }) => {
	const [value, setValue] = React.useState<Date | null>(date ? new Date(date) : new Date());

	const start = value && startOfWeek(value, { locale: nlBE });
	const end = value && endOfWeek(value, { locale: nlBE });

	React.useEffect(() => {
		onChange(start, end);
	}, [value]);

	const renderWeekPickerDay = (d: Date, _: Array<Date | null>, pickersDayProps: PickersDayProps<Date>) => {
		if (!value || !start || !end) {
			return <PickersDay {...pickersDayProps} />;
		}

		const dayIsBetween = isWithinInterval(d, { start, end });
		const isFirstDay = isSameDay(d, start);
		const isLastDay = isSameDay(d, end);

		return (
			<CustomPickersDay
				{...pickersDayProps}
				disableMargin
				dayIsBetween={dayIsBetween}
				isFirstDay={isFirstDay}
				isLastDay={isLastDay}
			/>
		);
	};

	let inputFormat;

	if (start && end) {
		inputFormat = `${format(start, "dd MMM", { locale: nlBE })} - ${format(end, "dd MMM yyyy", { locale: nlBE })}`;
	}

	const onSetValue = (v: Date | null) => {
		if (!v || Number.isNaN(v.getTime())) {
			return;
		}

		setValue(v);
	};

	const onTypeDate = (e: any) => {
		const d = new Date(e.currentTarget.value);

		if (Number.isNaN(d.getTime())) {
			return;
		}

		setValue(d);
	};

	const onClickNextWeek = () => {
		setValue(addWeeks(value || new Date(), 1));
	};

	const onClickPreviousWeek = () => {
		setValue(subWeeks(value || new Date(), 1));
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={nlBE}>
			<WeekPickerStyle>
				<IconButton onClick={onClickPreviousWeek}>
					<ArrowBackIos />
				</IconButton>
				<DatePicker
					label={i18next.t("weekpicker.week")}
					value={value}
					onChange={onSetValue}
					renderDay={renderWeekPickerDay}
					renderInput={(params) => <TextField variant="filled" {...params} onChange={onTypeDate} />}
					inputFormat={`'${inputFormat}'`}
				/>
				<IconButton onClick={onClickNextWeek}>
					<ArrowForwardIos />
				</IconButton>
			</WeekPickerStyle>
		</LocalizationProvider>
	);
};
