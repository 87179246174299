import styled from "styled-components";

export const CalendarGroupStyle = styled.div<{ day: number }>`
	grid-column: ${(props) => props.day};
`;

export const CalendarRowItem = styled.div`
	background-color: #F3F3F3;
	margin: 10px 0px;
	padding: 10px;
	border-radius: 10px;
`;

export const CalendarGroupItem = styled.div`
	margin-bottom: 10px;
`;

export const CalendarGroupRow = styled.div`
	padding: 0px 10px;
	display: flex;
	justify-content: space-between;
`;

export const TravelRouteTitle = styled.p`
	color: grey
`;
