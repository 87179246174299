import { UIComponent } from "@aptus/frontend-core";
import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import { Children, ReactNode } from "react";

interface Props {
	skeleton: ReactNode;
	isLoading?: boolean;
	/**
	 * @default true if component has no children
	 * @default false if component has children
	 * */
	isEmpty?: boolean;
	amount?: number;
	emptyState?: ReactNode;
}

const EmptyState: UIComponent = () => (
	<Box
		justifyContent="center"
		alignItems="center"
		width="100%"
		height="100%"
	>
		<Typography variant="body2" textAlign="center">{i18next.t("skeletonLoader.noData")}</Typography>
	</Box>
);

export const SkeletonLoader: UIComponent<Props> = ({
	isLoading, isEmpty, amount = 1, skeleton, children, emptyState = <EmptyState />,
}) => (
	<>
		{isLoading && [...Array(amount)].map(() => skeleton)}
		{!isLoading && (Children.count(children) === 0 || isEmpty) && emptyState}
		{!isLoading && (Children.count(children) > 0 && !isEmpty) && children}
	</>
);
