import { Colors } from "style/colors";
import { Layout } from "style/layout";
import styled from "styled-components";

export const DetailPageStyle = styled(Layout)`
	background-color: ${Colors.background};
`;

export const DetailPageContentStyle = styled.section`
	grid-column: 2;

	display: grid;
	grid-template-columns: 1fr 3fr;

	.container {
		background-color: ${Colors.white};

		.download-btn {
			color: ${Colors.white};
			text-decoration: none;
		}
	}

	.intervention-header {
		padding: 20px;
	}
`;
