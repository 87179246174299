import { Mutation, UseCases } from "@aptus/frontend-core";
import { event } from "event";
import i18next from "i18next";
import jwtDecode from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { HTMLValidationSchema } from "utils/validate";
import { LoginInput, RefreshTokenInput } from "./models/authenticationInput";
import { Profile, Role } from "./models/profile";

interface Tokens {
	accessToken: string;
	refreshToken: string;
}

interface JWTToken {
	roles: string[];
}

export type LoginMutation = Mutation<LoginInput, Tokens>;
export type RefreshTokenMutation = Mutation<RefreshTokenInput, Tokens>;

interface Props {
	loginMutation: LoginMutation;
	refreshTokenMutation: RefreshTokenMutation;
}

interface Result {
	profile: Profile;
	error: string;
	isLoading: boolean;
	loginSchema: HTMLValidationSchema<LoginInput>;
	login: (input: LoginInput | RefreshTokenInput) => Promise<void>;
	logout: () => void;
}

export interface AuthenticationEvents {
	loggedIn: () => void;
	loggedOut: () => void;
	tokenExpired: () => void;
}

export const useAuthenticationUseCases: UseCases<Props, Result> = ({ loginMutation, refreshTokenMutation }) => {
	const [error, setError] = useState<Result["error"]>("");
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const hasRole = (role: Role, ...roles: Role[]): boolean => roles.some((_role) => _role === role);
	const token = localStorage.getItem("accessToken");

	const getRole = (roles: string[] = []): Role => {
		if (roles.includes("ROLE_SCHEDULER")) return Role.Scheduler;
		return Role.Guest;
	};

	const profile: Result["profile"] = {
		role: getRole(token ? jwtDecode<JWTToken>(token).roles : []),
		hasRole: (roles) => hasRole(getRole(token ? jwtDecode<JWTToken>(token).roles : []), roles),
	};

	const loginSchema: Result["loginSchema"] = {
		username: { required: true },
		password: { required: true },
	};

	const clearLocalStorage = (): void => {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
	};

	const login: Result["login"] = useCallback(async (input) => {
		setError("");
		setIsLoading(true);
		clearLocalStorage();

		try {
			const tokens = "refreshToken" in input
				? await refreshTokenMutation(input)
				: await loginMutation(input);

			if (tokens) {
				localStorage.setItem("accessToken", tokens.accessToken);
				localStorage.setItem("refreshToken", tokens.refreshToken);
				event.emit("loggedIn");
			} else {
				throw new Error(i18next.t("domain.authentication.loginFailed"));
			}
		} catch (_error) {
			setError(i18next.t("domain.authentication.loginFailed"));
		} finally {
			setIsLoading(false);
		}
	}, []);

	const logout: Result["logout"] = () => {
		clearLocalStorage();
		event.emit("loggedOut");
	};

	return {
		profile,
		error,
		isLoading,
		loginSchema,
		login,
		logout,
	};
};

export const useAuthenticationEvents = (authentication: ReturnType<UseCases<Props, Result>>): void => {
	const refreshAccessToken = async () => {
		const refreshToken = localStorage.getItem("refreshToken");
		if (refreshToken) await authentication.login({ refreshToken });
	};

	useEffect(() => {
		event.on("tokenExpired", refreshAccessToken);

		return () => {
			event.removeListener("tokenExpired", refreshAccessToken);
		};
	}, [event, refreshAccessToken]);
};
