import { UILogic } from "@aptus/frontend-core";
import { LoginInput } from "hooks/authentication/models/authenticationInput";
import { FormEvent } from "react";
import {
	FieldErrors, FieldPath, useForm, UseFormRegisterReturn,
} from "react-hook-form";
import { HTMLValidationSchema, toFormHookSchema } from "utils/validate";

interface CommonProps {
	isLoading?: boolean;
	loginError?: string;
}

interface Props {
	login: (input: LoginInput) => void;
	schema: HTMLValidationSchema<LoginInput>;
}

interface Result {
	submit: (e: FormEvent<HTMLFormElement>) => void;
	register: (field: FieldPath<LoginInput>) => UseFormRegisterReturn;
	errors: FieldErrors<LoginInput>;
	isValid?: boolean;
}

export type LoginFormProps = CommonProps & Props;
export type LoginFormLayoutProps = CommonProps & Result;

export const useLoginForm: UILogic<Props, Result> = ({ login, schema }) => {
	const { handleSubmit, register, formState } = useForm<LoginInput>({ mode: "onChange" });

	return {
		submit: handleSubmit(login),
		register: (field) => register(field, toFormHookSchema(schema)[field]),
		errors: formState.errors,
		isValid: formState.isValid,
	};
};
