import { UILogic } from "@aptus/frontend-core";
import { useEffect, useReducer } from "react";
import { Order, Sort, sortReducer } from "./context/sortContext";
import { FilterFn, SortFn, tableReducer } from "./context/tableContext";

interface CommonProps {
	toolbar?: JSX.Element;
	rows: any[];
}

export interface TableLayoutProps extends CommonProps {
	unfilteredRows: any[];
	sortBy: Sort;
	sort: (property: string) => void;
	filter: (callback: FilterFn) => void;
	sortFn: SortFn;
	filterFn: FilterFn;
}

export type TableProps = CommonProps;

type Props = Omit<TableProps, keyof Omit<CommonProps, "rows">>;
type Result = Omit<TableLayoutProps, keyof Omit<CommonProps, "rows">>;

export const useTable: UILogic<Props, Result> = ({ rows }) => {
	const [sort, setSort] = useReducer(sortReducer, { orderBy: "", order: Order.Asc });
	const [tableState, setTableState] = useReducer(tableReducer, {
		unfilteredRows: [...rows],
		rows: [...rows],
		sort: () => 0,
		sortFn: () => 0,
		filterFn: () => true,
		filter: () => true,
	});

	const filter: Result["filter"] = (callback) => setTableState({ type: "filter", callback });
	const setRows = (_rows: any[]) => setTableState({ type: "setRows", rows: _rows });

	const desc = (a: any, b: any, orderBy: string) => {
		if (b[orderBy] < a[orderBy]) return -1;
		if (b[orderBy] > a[orderBy]) return 1;
		return 0;
	};

	const sortBy = ({ orderBy, order }: Sort) => (a: any, b: any) => {
		if (!orderBy) return 0;

		return order === Order.Desc
			? desc(a, b, orderBy)
			: -desc(a, b, orderBy);
	};

	useEffect(() => {
		setTableState({ type: "sort", callback: sortBy(sort) });
	}, [sort]);

	useEffect(() => {
		setRows(rows);
	}, [rows]);

	return {
		...tableState,
		sort: setSort,
		sortBy: sort,
		filter,
	};
};
