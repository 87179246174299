import { API, UseCases } from "@aptus/frontend-core";
import { DeduplicationUtils } from "utils/DeduplicationUtils";
import { Intervention } from "./models/intervention";

export type InterventionsAPI = API<Intervention[]>;

interface Props {
	api: InterventionsAPI;
	defaultIntervention: Intervention;
}

interface Result {
	interventions: Intervention[];
	intervention: Intervention;
	trucks: string[];
	isLoading: boolean;
	error?: Error;
}

export const useInterventionsUseCases: UseCases<Props, Result> = ({
	api, defaultIntervention,
}) => {
	const Deduplicate = new DeduplicationUtils();
	const trucks: Result["trucks"] = Deduplicate.primitives(api.data.map(({ truck }) => truck));

	return {
		...api,
		interventions: api.data,
		intervention: api.data?.[0] ?? defaultIntervention,
		trucks,
	};
};
