import { DeepPartial, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { QueryDTO, UserDTO } from "models/schema";
import { Installer } from "./models/installer";
import { InstallersAPI } from "./useInstallersUseCases";

export type InstallersAPIDTO = ApolloAPI<QueryDTO, "user" | "users">;

interface Mapper {
	toAPI: ToAPI<InstallersAPIDTO, InstallersAPI>;
	toInstaller: ToInternal<DeepPartial<UserDTO>, Installer>;
}

export class InstallerMapper implements Mapper {
	public toInstaller: ToInternal<DeepPartial<UserDTO>, Installer> = (installer) => ({
		id: installer.id || "",
		name: installer.userName || "",
	});

	public toAPI: ToAPI<InstallersAPIDTO, InstallersAPI> = (api) => {
		const extractDTOs = (data: InstallersAPIDTO["data"]): DeepPartial<UserDTO>[] => {
			if (data?.users) return data.users || [];
			if (data?.user) return [data.user];
			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toInstaller),
		};
	};
}
