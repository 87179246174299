import { createTheme, responsiveFontSizes } from "@mui/material";
import type { } from "@mui/lab/themeAugmentation";

export const DarkTheme = createTheme({
	palette: {
		mode: "dark",
	},
});

export const theme = responsiveFontSizes(createTheme({
	typography: {
		h1: {
			fontSize: 32,
			fontWeight: "bold",
		},
		h2: {
			fontSize: 24,
			fontWeight: "bold",
			color: "#4A9FF8",
			marginTop: 32,
		},
		h3: {
			fontSize: 24,
			fontWeight: "bold",
			color: "#333333",
		},
		body2: {
			color: "#878787",
		},
	},
	palette: {
		primary: {
			main: "#4A9FF8",
		},
		success: {
			main: "#67DE93",
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					color: "white",
					textTransform: "none",
					borderRadius: 100,
					px: 6,
				},
				outlined: {
					color: "#4A9FF8",
				},
			},
			defaultProps: {
				variant: "contained",
				disableElevation: true,
			},
		},
		MuiLoadingButton: {
			defaultProps: {
				variant: "contained",
				disableElevation: true,
				sx: {
					color: "white",
					textTransform: "none",
					borderRadius: 100,
					px: 6,
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				sx: {
					borderRadius: 2,
					margin: 1,
				},
				size: "small",
				// helperText: " ",
			},
		},
		MuiCard: {
			defaultProps: {
				sx: {
					p: 2,
					".MuiCardActionArea-root": {
						width: "auto",
						p: 2,
						m: -2,
					},
				},
			},
		},
		MuiTab: {
			defaultProps: {
				sx: {
					textTransform: "none",
					"&.Mui-selected": {
						color: "#FFFFFF",
					},
				},
			},
		},
		MuiTabs: {
			defaultProps: {
				sx: {
					".MuiTabs-indicator": {
						backgroundColor: "#FFFFFF",
						height: "4px",
						borderRadius: "2px",
					},
				},
			},
		},
	},
}));
