import { UIComponent } from "@aptus/frontend-core";
import { TableBody as MUITableBody } from "@mui/material";
import { useContext } from "react";
import { TableContext } from "./context/tableContext";
import { TableRow } from "./tableRow";

interface Props {
	isLoading?: boolean;
}

export const TableBody: UIComponent<Props> = ({ children }) => {
	const { rows } = useContext(TableContext);

	return (
		<MUITableBody>
			{rows.map((row, index) => (
				<TableRow row={row} index={index}>
					{children}
				</TableRow>
			))}
		</MUITableBody>
	);
};
