import { UIComponent } from "@aptus/frontend-core";
import { Skeleton } from "@mui/material";
import { getDay } from "date-fns";
import { Intervention } from "hooks/interventions/models/intervention";
import { WithLoadingState } from "models/withLoadingState";
import { useNavigate } from "react-router-dom";
import { getStatusColor, showStatusIcon } from "utils/serviceReportUtils";
import {
	BottomContainer,
	CalendarItemStyle,
	CalendarItemTitle,
	CalenderItemAddress,
	CalenderItemPhoneNumber,
} from "./CalendarStyle";

type Props = Pick<Intervention, "id" | "url" | "customer" | "date" | "status">;

export const CalendarItem: UIComponent<WithLoadingState<Props>> = ({
	isLoading, id, url = "", customer, date = new Date(), status,
}) => {
	const navigate = useNavigate();

	return (
		<CalendarItemStyle onClick={() => navigate(url)} key={id} day={getDay(date)} statusColor={getStatusColor(status)}>
			<CalendarItemTitle>{isLoading ? <Skeleton variant="text" /> : customer?.name}</CalendarItemTitle>
			<CalenderItemAddress>
				{isLoading
					? <Skeleton variant="text" />
					: `${customer?.address?.addressLine} ${customer?.address?.postalCode} ${customer?.address?.city}`.trim()}
			</CalenderItemAddress>
			{isLoading
				? <Skeleton variant="text" />
				: (
					<BottomContainer>
						<CalenderItemPhoneNumber href={`tel:${customer?.phoneNumber}`}>{customer?.phoneNumber}</CalenderItemPhoneNumber>
						{showStatusIcon(status)}
					</BottomContainer>
				)}
		</CalendarItemStyle>
	);
};
