import { FunctionComponent, StrictMode } from "react";
import ReactDOM from "react-dom";
import { worker } from "mocks/worker";
import { GlobalStyle } from "style/global";
import "i18n";
import { ApolloProvider } from "@apollo/client";
import { client } from "client";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "theme";
import { reportWebVitals } from "./reportWebVitals";
import { Content } from "./Routes";

// Set `onUnhandledRequest` to `warn` to log unhandled requests to the console.
if (process.env.REACT_APP_ENVIRONMENT === "local") {
	worker.start({
		onUnhandledRequest: "bypass",
	});
}

const App: FunctionComponent = () => (
	<StrictMode>
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<ApolloProvider client={client}>
				<BrowserRouter>
					<CssBaseline />
					<Content />
				</BrowserRouter>
			</ApolloProvider>
		</ThemeProvider>
	</StrictMode>
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
