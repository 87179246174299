import { createContext } from "react";

type Row = any;
type ColumnName = string;

export type SortFn = (rowA: Row, rowB: Row) => number;
export type FilterFn = (row: Row, index: number, rows: Row[]) => boolean;

interface State {
	unfilteredRows: Row[];
	rows: Row[];
	// Persist the filterFn and sortFn across table updates.
	filterFn: FilterFn;
	sortFn: SortFn;
	filter: (callback: FilterFn) => void;
	sort: (sortBy: ColumnName) => void;
}

interface SortAction {
	type: "sort";
	callback: SortFn;
}

interface FilterAction {
	type: "filter";
	callback: FilterFn;
}

interface SetRowsAction {
	type: "setRows";
	rows: Row[];
}

type Action = SortAction | FilterAction | SetRowsAction;

export const tableReducer = (state: State, action: Action): State => {
	const { sortFn, unfilteredRows, filterFn } = state;
	const newState = { ...state };

	switch (action.type) {
		case "sort":
			newState.sortFn = action.callback;
			newState.rows = [...unfilteredRows].sort(action.callback).filter(filterFn);
			break;
		case "filter":
			newState.filterFn = action.callback;
			newState.rows = [...unfilteredRows].filter(action.callback).sort(sortFn);
			break;
		case "setRows":
			newState.unfilteredRows = [...action.rows];
			newState.rows = action.rows;
			break;
		default:
			return state;
	}

	return newState;
};

export const TableContext = createContext<State>({
	unfilteredRows: [],
	rows: [],
	filterFn: () => true,
	filter: () => true,
	sortFn: () => 0,
	sort: () => 0,
});
