import { UIComponent } from "@aptus/frontend-core";
import { TableCell as MUITableCell, TableSortLabel, TableCellProps as MUITableCellProps } from "@mui/material";
import { ReactNode, useContext } from "react";
import { SortContext } from "./context/sortContext";

interface CommonProps extends MUITableCellProps {
	property: string;
}

interface BodyCellProps extends CommonProps {
	row?: any;
	index?: number;
	children?: never;
}

interface HeadCellProps extends CommonProps {
	row?: never;
	index?: never;
	children: ReactNode;
}

export type TableCellProps = BodyCellProps | HeadCellProps;

export const TableCell: UIComponent<TableCellProps> = ({
	row, children, property, ...rest
}) => (
	<MUITableCell {...rest} sx={{ fontWeight: "inherit", ...rest.sx }}>
		{row ? row[property] : children}
	</MUITableCell>
);

export const TableHeadCell: UIComponent<HeadCellProps> = ({ property, children, ...rest }) => {
	const { sort, setSort } = useContext(SortContext);

	return (
		<TableCell property={property} {...rest}>
			<TableSortLabel
				active={sort.orderBy === property}
				direction={sort.orderBy === property ? sort.order : undefined}
				onClick={() => setSort(property as string)}
				sx={{ fontWeight: "inherit" }}
			>
				{children}
			</TableSortLabel>
		</TableCell>
	);
};
