import { drop, factory } from "@mswjs/data";
import faker from "faker";
import { populateInterventionCollection } from "./collections/intervention";
import { dictionary } from "./dictionary";

export const db = factory(dictionary);
export type Database = typeof db;

export const rollbackDatabase = () => {
	drop(db);
	faker.seed(123);

	populateInterventionCollection(db);
};

rollbackDatabase();
