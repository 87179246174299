import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { UIComponent } from "@aptus/frontend-core";
import "lightbox2/dist/css/lightbox.css";
import "lightbox2/dist/js/lightbox-plus-jquery";

interface Props {
	images: string[];
	alt: string;
}

export const Gallery: UIComponent<Props> = ({ images, alt }) => (
	<ImageList style={{ overflowY: "hidden" }} variant="woven" sx={{ width: 500 }}>
		{images.map((src) => (
			<a href={src} data-lightbox="images">
				<ImageListItem key={src}>
					<img src={src} alt={alt} />
					<ImageListItemBar
						position="top"
						sx={{ backgroundColor: "transparent", padding: "1rem" }}
					/>
				</ImageListItem>
			</a>
		))}
	</ImageList>
);
