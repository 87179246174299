import { UIComponent } from "@aptus/frontend-core";
import { format } from "date-fns";
import { nlBE } from "date-fns/locale";
import i18next from "i18next";
import { TravelRoute } from "hooks/travelRoutes/models/travelRoute";
import {
	CalendarGroupRow,
	CalendarGroupStyle,
	CalendarRowItem,
	TravelRouteTitle,
} from "./CalendarGroupStyle";
import { CalendarItem } from "../calendar/CalendarItem";

interface CalendarGroupProps {
	startDate: number;
	item: TravelRoute[];
}

export const CalendarGroup: UIComponent<CalendarGroupProps> = ({ startDate, item }) => (
	<CalendarGroupStyle day={startDate}>
		{item.map((value) => {
			if (value.interventions.length === 0) {
				return <div />;
			}

			const intCode = value.interventions?.[0]?.interventionNumber;
			const licensePlate = value.interventions?.[0]?.serviceReport?.licensePlate;
			const {
				start,
				startKm,
				end,
				endKm,
			} = value.workingHour;

			return (
				<CalendarRowItem>
					<CalendarGroupRow>
						<TravelRouteTitle>{i18next.t("calendarCard.intNumber")}</TravelRouteTitle>
						<p style={{ fontWeight: "bold" }}>{intCode}</p>
					</CalendarGroupRow>
					{licensePlate && (
						<CalendarGroupRow>
							<TravelRouteTitle>{i18next.t("calendarCard.car")}</TravelRouteTitle>
							<p>{licensePlate}</p>
						</CalendarGroupRow>
					)}
					{start && (
						<CalendarGroupRow>
							<TravelRouteTitle>{i18next.t("calendarCard.startTime")}</TravelRouteTitle>
							<p>{format(new Date(start), "HH:mm", { locale: nlBE })}</p>
						</CalendarGroupRow>
					)}
					{start && (startKm !== undefined || startKm !== null) && (
						<CalendarGroupRow>
							<TravelRouteTitle>{i18next.t("calendarCard.startKm")}</TravelRouteTitle>
							<p>{startKm}</p>
						</CalendarGroupRow>
					)}
					{end && (
						<CalendarGroupRow>
							<TravelRouteTitle>{i18next.t("calendarCard.endTime")}</TravelRouteTitle>
							<p>{format(new Date(end), "HH:mm", { locale: nlBE })}</p>
						</CalendarGroupRow>
					)}
					{end && (endKm !== undefined || endKm !== null) && (
						<CalendarGroupRow>
							<TravelRouteTitle>{i18next.t("calendarCard.endKm")}</TravelRouteTitle>
							<p>{endKm}</p>
						</CalendarGroupRow>
					)}
					{value.interventions.map((v) => {
						const newURL = v.url.replace(":travelrouteid", value.id);
						const objectTest = { ...v, url: newURL };
						return (
							<CalendarItem key={v.id} {...objectTest} />
						);
					})}
				</CalendarRowItem>
			);
		})}
	</CalendarGroupStyle>
);
