import { Colors } from "style/colors";
import styled from "styled-components";

export const PdfViewerStyle = styled.div`
	background-color: ${Colors.black};
	display: flex;
	justify-content: center;
	padding: 20px;
	overflow: scroll;
	max-height: 900px;

	.react-pdf__Document {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.react-pdf__Page {
		margin-bottom: 10px;
	}
`;
