/* eslint-disable react/jsx-no-constructed-context-values */
// Context values should not be memoized in order to get the new row values when the table is updated.
import { UILayout, UIComponent } from "@aptus/frontend-core";
import { Box, Table as MUITable } from "@mui/material";
import { SortContext } from "./context/sortContext";
import { TableContext } from "./context/tableContext";
import { TableLayoutProps, TableProps, useTable } from "./useTable";

const Layout: UILayout<TableLayoutProps> = ({
	children, toolbar, rows, filter, filterFn, sort, sortFn, unfilteredRows, sortBy,
}) => (
	<Box sx={{ width: "100%" }}>
		<TableContext.Provider value={{
			rows, unfilteredRows, filter, filterFn, sort, sortFn,
		}}
		>
			<SortContext.Provider value={{ sort: sortBy, setSort: sort }}>
				{toolbar}
				<MUITable size="medium">
					{children}
				</MUITable>
			</SortContext.Provider>
		</TableContext.Provider>
	</Box>
);

export const Table: UIComponent<TableProps> = (props) => (
	<Layout {...props} {...useTable(props)} />
);
