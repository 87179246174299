import { PageComponent } from "@aptus/frontend-core";
import { Skeleton } from "@mui/material";
import { Gallery } from "components/gallery";
import { SkeletonLoader } from "components/skeletonLoader";
import { startOfDay, endOfDay } from "date-fns";
import {
	useInterventions,
	useRoutes,
	useServiceReports,
	useTravelRoutes,
} from "domains";
import i18next from "i18next";
import { InterventionStatusDTO } from "models/schema";
import { GetInterventionsVariablesDTO } from "pages/overviewPage/models/getInterventions";
import { GetTravelRoutesVariablesDTO, useGetTravelRoutes } from "pages/overviewPage/models/getTravelRoutes";
import { Sidebar } from "./components/sidebar/Sidebar";
import { DetailPageContentStyle, DetailPageStyle } from "./DetailPageStyle";
import { EmptyState } from "./components/emptyState";
import { useGetInterventionDetail } from "./models/getInterventionDetail";
import { ServiceReportForm } from "./components/serviceReportForm/serviceReportForm";
import { PdfViewer } from "./components/pdfViewer/PdfViewer";

const setupFilter = (truck: string, installers: string[] | null, startDate: string, endDate: string) => {
	const filter: GetTravelRoutesVariablesDTO["filter"] = {
		OR: [],
		truckId: { equals: truck },
		employee: { containsSome: installers },
		startingHour: {
			gte: startDate,
			lte: endDate,
		},
	};

	return filter;
};

const interventionFilter = (status: InterventionStatusDTO) => {
	const filterStatus = status.length > 0 ? status : undefined;
	const filter: GetInterventionsVariablesDTO["filter"] = {
		status: {
			equals: filterStatus,
		},
	};
	return filter;
};

export const DetailPage: PageComponent<{}> = () => {
	const { routes, params, getQuery } = useRoutes();
	const { saveSchema, save, isLoading } = useServiceReports();
	const { intervention, isLoading: isInterventionLoading } = useInterventions(useGetInterventionDetail({
		variables: {
			id: params.id || "",
		},
	}));
	const { travelRoutes, isLoading: isTravelRouteLoading } = useTravelRoutes(useGetTravelRoutes({
		variables: {
			filter: setupFilter(
				getQuery("truck"),
				getQuery("installer") ? [getQuery("installer")] : null,
				startOfDay(intervention.date || new Date()).toISOString(),
				endOfDay(intervention.date || new Date()).toISOString(),
			),
			interventionFilter: interventionFilter(getQuery("status") as InterventionStatusDTO),
		},
	}));

	return (
		<DetailPageStyle>
			<DetailPageContentStyle>
				<Sidebar
					isLoading={isTravelRouteLoading}
					date={intervention.date}
					travelRoutes={travelRoutes}
					href={routes.overview}
				/>
				<SkeletonLoader
					isLoading={isInterventionLoading}
					skeleton={<Skeleton variant="text" />}
					isEmpty={!intervention.serviceReport}
					emptyState={<EmptyState date={intervention.date} />}
				>
					<section className="container">
						<header className="intervention-header">
							<h2>{i18next.t("detail.title")}</h2>
							<h3>{i18next.t("detail.internalRemark")}</h3>

							{intervention.serviceReport && (
								<>
									<ServiceReportForm
										initialValues={intervention.serviceReport}
										schema={saveSchema}
										submit={save}
										isLoading={isLoading}
										pdfUrl={intervention.serviceReport?.pdfUrl}
									/>
									<Gallery
										images={intervention.serviceReport.images}
										alt={intervention.customer.name}
									/>
								</>
							)}

						</header>
						{intervention.serviceReport?.pdfUrl && (
							<PdfViewer url={intervention.serviceReport.pdfUrl} />
						)}
					</section>
				</SkeletonLoader>
			</DetailPageContentStyle>
		</DetailPageStyle>
	);
};
