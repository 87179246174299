import { UILogic } from "@aptus/frontend-core";
import React from "react";

interface Result {
	pages: string[];
	onLoadSuccess: (data: { numPages: number }) => void;
}

export const usePdfViewer: UILogic<{}, Result> = () => {
	const [pdfPages, setPdfPages] = React.useState(0);

	const onLoadSuccess: Result["onLoadSuccess"] = ({ numPages }) => {
		setPdfPages(numPages);
	};

	const pages = new Array(pdfPages).fill("-");

	return {
		pages,
		onLoadSuccess,
	};
};
