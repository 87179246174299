import styled, { createGlobalStyle } from "styled-components";
import { Colors } from "./colors";

/**
 * Default look for all the standard HTML elements.
 */

export const GlobalStyle = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	#root {
		min-height: 100vh;
		background-color: ${Colors.background};
	}

	html {
		font-size: 16px; /* 1rem = 2 x 8pt grid units. */
	}
`;

export const Flex = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

export const ButtonContainer = styled.div`
	display: flex;
	margin-bottom: 10px;

	button {
		margin-left: 10px;
	}

	width: 100%;
	justify-content: flex-end;
`;

export const Item = styled.article`
	cursor: pointer;
	background-color: white;
	padding: 10px;
	border-radius: 3px;
	min-height: 150px;
	-webkit-box-shadow: 0px 4px 4px 0px rgba(209,209,209,0.5);
	-moz-box-shadow: 0px 4px 4px 0px rgba(209,209,209,0.5);
	box-shadow: 0px 4px 4px 0px rgba(209,209,209,0.5);
`;
