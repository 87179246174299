import { Colors } from "style/colors";
import { Item } from "style/global";
import styled from "styled-components";

export const CalendarStyle = styled.section`
	padding: 10px;
	width: 100%;
	display: grid;
	grid-gap: 15px;

	grid-auto-flow: dense;

	grid-template-columns: repeat(7, minmax(0, 1fr));
`;

export const CalendarHeaderItem = styled.div`
	grid-row: 1;

	.title {
		text-transform: capitalize;
	}

	.date {
		opacity: 0.5;
	}
`;

interface CalendarItemStyleProps {
	day: number;
	statusColor?: string;
}

export const CalendarItemStyle = styled(Item)<CalendarItemStyleProps>`
	grid-column: ${(props) => props.day};
	margin: 10px 0px;
	border-left: 5px solid;
	border-left-color: ${({ statusColor }) => statusColor ?? "white"};
`;

export const CalendarItemTitle = styled.h1`
	margin-bottom: 10px;
`;

export const CalenderItemAddress = styled.address`
	font-style: normal;
	opacity: 0.5;
	margin-bottom: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const CalenderItemPhoneNumber = styled.a<{ href: string }>`
	text-decoration: none;
	color: ${Colors.black};
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const BottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
