import { UIComponent } from "@aptus/frontend-core";
import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import timeLeftIcon from "assets/images/time-left.svg";
import { format } from "i18n";

interface Props {
	date: Date;
}

export const EmptyState: UIComponent<Props> = ({ date }) => (
	<Box
		display="flex"
		flexDirection="column"
		justifyContent="center"
		alignItems="center"
		width="100%"
		height="80vh"
	>
		<img src={timeLeftIcon} alt={i18next.t("emptyState.alt")} />
		<Typography variant="h2" textAlign="center">{i18next.t("emptyState.title")}</Typography>
		<br />
		<Typography variant="body2" textAlign="center">{i18next.t("emptyState.description")}</Typography>
		<br />
		<Typography variant="body1" textAlign="center">
			{i18next.t("emptyState.interventionDate", { date: format(date, "dd MMMM yyyy") })}
		</Typography>
	</Box>
);
