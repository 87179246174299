import { UIComponent } from "@aptus/frontend-core";
import { Typography, TableCell as MUITableCell } from "@mui/material";
import { Table } from "components/table/table";
import { TableBody } from "components/table/tableBody";
import { TableCell, TableCellProps } from "components/table/tableCell";
import { TableHead } from "components/table/tableHead";
import { Article } from "hooks/interventions/models/intervention";
import i18next from "i18next";

type Row = Pick<Article, "amount" | "reference" | "description">;

interface Props {
	articles: Row[];
}

const ReferenceTableCell: UIComponent<TableCellProps> = ({ row, property }) => (
	<MUITableCell>
		<Typography variant="body2">{row[property]}</Typography>
	</MUITableCell>
);

export const ArticlesTable: UIComponent<Props> = ({ articles }) => (articles.length > 0
	? (
		<Table rows={articles}>
			<TableHead>
				<TableCell property="amount">{i18next.t("articlesTable.amount")}</TableCell>
				<TableCell property="reference">{i18next.t("articlesTable.reference")}</TableCell>
				<TableCell property="description">{i18next.t("articlesTable.description")}</TableCell>
			</TableHead>
			<TableBody>
				<TableCell property="amount" />
				<ReferenceTableCell property="reference" />
				<TableCell property="description" />
			</TableBody>
		</Table>
	)
	: null);
