import { Domain } from "@aptus/frontend-core";
import { AuthenticationMapper } from "hooks/authentication/AuthenticationMapper";
import { useLogin } from "hooks/authentication/models/login";
import { useRefreshToken } from "hooks/authentication/models/refreshToken";
import { useAuthenticationUseCases } from "hooks/authentication/useAuthenticationUseCases";
import { InstallerMapper, InstallersAPIDTO } from "hooks/installers/InstallerMapper";
import { useInstallersUseCases } from "hooks/installers/useInstallersUseCases";
import { InterventionMapper, InterventionsAPIDTO } from "hooks/interventions/InterventionMapper";
import { useInterventionsUseCases } from "hooks/interventions/useInterventionsUseCases";
import { useRoutesUseCases } from "hooks/routes/useRoutesUseCases";
import { useUpdateServiceReport } from "hooks/serviceReports/models/updateServiceReport";
import { ServiceReportMapper, ServiceReportsAPIDTO } from "hooks/serviceReports/ServiceReportMapper";
import { useServiceReportsUseCases } from "hooks/serviceReports/useServiceReportsUseCases";
import { TravelRouteMapper, TravelRoutesAPIDTO } from "hooks/travelRoutes/travelRoutesMapper";
import { useTravelRoutesUseCases } from "hooks/travelRoutes/useTravelRoutesUseCases";

export const useRoutes: Domain<undefined, typeof useRoutesUseCases> = useRoutesUseCases;

export const useAuthentication: Domain<undefined, typeof useAuthenticationUseCases> = () => {
	const map = new AuthenticationMapper();
	const [loginMutation] = useLogin();
	const [refreshTokenMutation] = useRefreshToken();

	return useAuthenticationUseCases({
		loginMutation: map.toLoginMutation(loginMutation),
		refreshTokenMutation: map.toRefreshTokenMutation(refreshTokenMutation),
	});
};

export const useInstallers: Domain<InstallersAPIDTO, typeof useInstallersUseCases> = (api) => {
	const map = new InstallerMapper();

	return useInstallersUseCases({
		api: map.toAPI(api),
		defaultInstaller: map.toInstaller({}),
	});
};

export const useInterventions: Domain<InterventionsAPIDTO, typeof useInterventionsUseCases> = (api) => {
	const { routes } = useRoutes();
	const map = new InterventionMapper({
		toServiceReport: new ServiceReportMapper().toServiceReport,
		toInstaller: new InstallerMapper().toInstaller,
		createURL: (id) => routes.detail.replace(":id", id),
	});

	return useInterventionsUseCases({
		api: map.toAPI(api),
		defaultIntervention: map.toIntervention({}),
	});
};

export const useTravelRoutes: Domain<TravelRoutesAPIDTO, typeof useTravelRoutesUseCases> = (api) => {
	const { routes } = useRoutes();
	const map = new TravelRouteMapper({
		toIntervention: new InterventionMapper({
			toServiceReport: new ServiceReportMapper().toServiceReport,
			toInstaller: new InstallerMapper().toInstaller,
			createURL: (id) => routes.detail.replace(":id", id),
		}).toIntervention,
	});

	return useTravelRoutesUseCases({
		api: map.toAPI(api),
		defaultTravelRoute: map.toTravelRoute({}),
	});
};

export const useServiceReports: Domain<ServiceReportsAPIDTO, typeof useServiceReportsUseCases> = (api) => {
	const map = new ServiceReportMapper();
	const [updateMutation] = useUpdateServiceReport({ refetchQueries: ["getInterventionDetail", "getInterventions"], awaitRefetchQueries: true });

	return useServiceReportsUseCases({
		api: map.toAPI(api),
		defaultServiceReport: map.toServiceReport({}),
		updateMutation: map.toUpdateMutation(updateMutation),
	});
};
