import {
	DeepPartial, IOMap, ToAPI, ToInternal,
} from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import {
	QueryDTO, ServiceReportDTO, TimeBlockDTO,
} from "models/schema";
import {
	ServiceReport, RemainingWork, TimeBlock, Mileage,
} from "./models/serviceReport";
import { UpdateServiceReportMutationFnDTO } from "./models/updateServiceReport";
import { ServiceReportsAPI, UpdateServiceReportMutation } from "./useServiceReportsUseCases";

export type ServiceReportsAPIDTO = ApolloAPI<QueryDTO, "serviceReport" | "serviceReports">;

interface Mapper {
	toAPI: ToAPI<ServiceReportsAPIDTO, ServiceReportsAPI>;
	toServiceReport: ToInternal<DeepPartial<ServiceReportDTO>, ServiceReport>;
	toUpdateMutation: ToInternal<UpdateServiceReportMutationFnDTO, UpdateServiceReportMutation>;
}

export class ServiceReportMapper implements Mapper {
	private toTimeBlock = (timeBlock: DeepPartial<TimeBlockDTO>): TimeBlock => ({
		from: new Date(timeBlock.start),
		to: timeBlock.end ? new Date(timeBlock.end) : undefined,
	});

	private toRemainingWork = (serviceReport: DeepPartial<ServiceReportDTO>): RemainingWork => ({
		description: serviceReport.remainingWork || "",
		manPower: serviceReport.remainingWorkManPower || 0,
	});

	private toMileage = (serviceReport: DeepPartial<ServiceReportDTO>): Mileage => ({
		start: serviceReport.startKm || 0,
		end: serviceReport.endKm || serviceReport.startKm || 0,
	});

	public toServiceReport: Mapper["toServiceReport"] = (serviceReport) => ({
		id: serviceReport.id || "",
		pdfUrl: serviceReport.pdfUrl || "",
		articlesRetoured: serviceReport.articlesRetoured || "",
		articlesToOrder: serviceReport.articlesToOrder || "",
		internalRemarks: serviceReport.remarksForInternal?.map((r) => ({ message: r.message || "", id: r.id || "" })) || [],
		images: (serviceReport.pictures || []).map(({ url }) => url || "").filter((url) => !!url),
		workingHours: (serviceReport.workingHours || []).map(this.toTimeBlock),
		executedWork: serviceReport.executedWork || "",
		remainingWork: this.toRemainingWork(serviceReport),
		remarksForCustomer: serviceReport.remarkForCustomer?.message || "",
		remarkForInternalTeam: serviceReport.remarksForInternal?.[0]?.message || "",
		mileage: this.toMileage(serviceReport),
		commentFromCustomer: serviceReport.remarkFromCustomer?.message || "",
		commentForCustomer: serviceReport.remarkForCustomer?.message || "",
		licensePlate: serviceReport.vehicleIdentifier || "",
		signature: serviceReport.signature?.url || "",
		completed: serviceReport.completed ?? false,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: ServiceReportsAPIDTO["data"]): DeepPartial<ServiceReportDTO>[] => {
			if (data?.serviceReports) return data.serviceReports;
			if (data?.serviceReport) return [data.serviceReport];
			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toServiceReport),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateServiceReportMutationFnDTO, UpdateServiceReportMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					remarksForInternal: input.internalRemarks || [],
				},
			}),
			toOutput: (output) => (output.data?.updateOneServiceReport
				? this.toServiceReport(output.data?.updateOneServiceReport)
				: undefined),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
