import { UseCases } from "@aptus/frontend-core";
import {
	Params, useParams, useNavigate, useLocation,
} from "react-router-dom";
import qs from "query-string";
import { event } from "event";
import { useEffect } from "react";
import { Routes } from "./models/routes";

interface Result {
	/** Used to navigate to routes. */
	routes: Routes;
	/** Used to define routes. */
	baseRoutes: Routes;
	params: Params;
	setQuery: (query: Record<string, string | undefined>) => void;
	getQuery: (parameter: string) => string;
	goToOverview: () => void;
	goToLogin: () => void;
}

export const useRoutesUseCases: UseCases<undefined, Result> = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const params: Result["params"] = useParams();

	const baseRoutes: Result["baseRoutes"] = {
		overview: "/",
		detail: "travelroutes/:travelrouteid/interventies/:id",
		pdf: "/interventies/:id/pdf",
		login: "/login",
	};

	const routes: Result["routes"] = {
		overview: `${baseRoutes.overview}${location.search}`,
		detail: `${baseRoutes.detail}${location.search}`,
		pdf: baseRoutes.pdf,
		login: baseRoutes.login,
	};

	const setQuery: Result["setQuery"] = (_query) => {
		const oldSearch = qs.parse(location.search);
		const search = qs.stringify({ ...oldSearch, ..._query });
		navigate({
			search,
		});
	};

	const getQuery: Result["getQuery"] = (parameter) => new URLSearchParams(location.search).get(parameter) || "";

	const goToOverview: Result["goToOverview"] = () => navigate(routes.overview);
	const goToLogin: Result["goToLogin"] = () => navigate(routes.login);

	return {
		routes,
		baseRoutes,
		params,
		setQuery,
		getQuery,
		goToOverview,
		goToLogin,
	};
};

export const useRoutesEvents = (routes: ReturnType<UseCases<undefined, Result>>): void => {
	useEffect(() => {
		event.on("loggedIn", routes.goToOverview);
		event.on("loggedOut", routes.goToLogin);

		return () => {
			event.removeListener("loggedIn", routes.goToOverview);
			event.removeListener("loggedOut", routes.goToLogin);
		};
	}, []);
};
