import { DeepPartial, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import {
	InterventionDTO,
	QueryDTO,
	TravelRouteDTO,
	TravelRouteWorkingHourDTO,
} from "models/schema";
import { Intervention } from "hooks/interventions/models/intervention";
import { TravelRoute, WorkingHour } from "./models/travelRoute";
import { TravelRoutesAPI } from "./useTravelRoutesUseCases";

export type TravelRoutesAPIDTO = ApolloAPI<QueryDTO, "travelRoutes" | "travelRoute">;

interface Props {
	toIntervention: ToInternal<DeepPartial<InterventionDTO>, Intervention>;
}

interface Mapper {
	toAPI: ToAPI<TravelRoutesAPIDTO, TravelRoutesAPI>
	toTravelRoute: ToInternal<DeepPartial<TravelRouteDTO>, TravelRoute>;
}

export class TravelRouteMapper implements Mapper {
	private toIntervention: Props["toIntervention"];

	constructor(props: Props) {
		this.toIntervention = props.toIntervention;
	}

	private toWorkingHour: ToInternal<DeepPartial<TravelRouteWorkingHourDTO>, WorkingHour> = (workingHour) => ({
		start: workingHour.start,
		startKm: workingHour.startKm ?? 0,
		end: workingHour.end,
		endKm: workingHour.endKm ?? 0,
	});

	public toTravelRoute: Mapper["toTravelRoute"] = (travelRoute) => ({
		id: travelRoute.id ?? "",
		startingHour: travelRoute.startingHour ?? new Date(),
		truckId: travelRoute.truckId ?? "",
		interventions: (travelRoute.interventions ?? []).map(this.toIntervention),
		workingHour: this.toWorkingHour(travelRoute.workingHour || {}),
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: TravelRoutesAPIDTO["data"]): DeepPartial<TravelRouteDTO>[] => {
			if (data?.travelRoutes) return data.travelRoutes || [];
			if (data?.travelRoute) return [data.travelRoute];
			return [];
		};

		return {
			...api,
			isLoading: api?.loading ?? false,
			data: extractDTOs(api?.data).map(this.toTravelRoute),
		};
	};
}
