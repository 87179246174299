import { UIComponent } from "@aptus/frontend-core";
import {
	FormControl, InputLabel, MenuItem, Select as MuiSelect, SelectProps,
} from "@mui/material";

interface Props extends SelectProps {
	minWidth: number;
	items: {
		value: string | number | undefined;
		label: string;
	}[];
}

export const Select: UIComponent<Props> = ({ items, minWidth, ...props }) => (
	<FormControl variant="standard" sx={{ m: 1, minWidth }}>
		<InputLabel>{props.label}</InputLabel>
		<MuiSelect
			{...props}
		>
			{items.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
		</MuiSelect>
	</FormControl>
);
