import { UIComponent } from "@aptus/frontend-core";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getStatusColor, showStatusIcon } from "utils/serviceReportUtils";
import { Intervention } from "hooks/interventions/models/intervention";
import { WithLoadingState } from "models/withLoadingState";
import { CalendarItemTitle, CalenderItemAddress, CalenderItemPhoneNumber } from "pages/overviewPage/components/calendar/CalendarStyle";
import { BottomContainer, SidebarItemStyle } from "./sidebarItemStyle";

export type WithActive<Type> = Type & {
	isActive: boolean;
};

type Props = WithActive<Pick<Intervention, "id" | "url" | "customer" | "status">>;

export const SidebarItem: UIComponent<WithLoadingState<Props>> = ({
	id, url = "", customer, isActive = false, isLoading, status,
}) => {
	const navigate = useNavigate();
	return (
		<SidebarItemStyle onClick={() => navigate(`/${url}`)} key={id} highlighted={isActive} statusColor={getStatusColor(status)}>
			<CalendarItemTitle>{isLoading ? <Skeleton variant="text" /> : customer?.name}</CalendarItemTitle>
			<CalenderItemAddress>
				{isLoading
					? <Skeleton variant="text" />
					: `${customer?.address?.addressLine} ${customer?.address?.postalCode} ${customer?.address?.city}`.trim()}
			</CalenderItemAddress>
			{isLoading
				? <Skeleton variant="text" />
				: (
					<BottomContainer>
						<CalenderItemPhoneNumber href={`tel:${customer?.phoneNumber}`}>{customer?.phoneNumber}</CalenderItemPhoneNumber>
						{showStatusIcon(status)}
					</BottomContainer>
				)}
		</SidebarItemStyle>
	);
};
