import {
	manyOf, nullable, oneOf, primaryKey,
} from "@mswjs/data";
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary";
import faker from "faker";
import {
	FileDTO, InterventionDTO, RemarkDTO, ServiceReportDTO, UserDTO,
} from "models/schema.d";

const defineEntity = <Entity>(
	generator: () => { [Key in keyof Entity]: ModelDefinitionValue },
) => generator();

const intervention = defineEntity<InterventionDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "Intervention",
	articles: () => [],
	completed: faker.datatype.boolean,
	customerAddress: {
		city: faker.address.city,
		email: faker.internet.email,
		phoneNumber: faker.phone.phoneNumber,
		name: faker.name.findName,
		postalcode: faker.address.zipCode,
		street: faker.address.streetAddress,
	},
	dealerId: faker.datatype.uuid,
	dealerName: faker.company.companyName,
	executionNumber: faker.datatype.number,
	executionText: faker.lorem.sentences,
	interventionCode: faker.datatype.uuid,
	interventionType: faker.datatype.uuid,
	mainUser: oneOf("user"),
	model: faker.datatype.string,
	orderText: faker.lorem.sentence,
	orderType: faker.lorem.word,
	reference: faker.lorem.word,
	remarks: faker.lorem.sentence,
	travelRoute: {
		employees: manyOf("user"),
		id: faker.datatype.uuid,
		intervention: () => [],
		startingHour: faker.datatype.datetime,
		truckId: faker.datatype.uuid,
	},
	customerType: () => "reseller",
	interventionDate: () => faker.datatype.datetime().toISOString(),
	deliveryAddress: {
		city: faker.address.city,
		email: faker.internet.email,
		phoneNumber: faker.phone.phoneNumber,
		name: faker.name.findName,
		postalcode: faker.address.zipCode,
		street: faker.address.streetName,
	},
	orderNumber: faker.datatype.number,
	serviceReport: oneOf("serviceReport", { nullable: true }),
	warehouseLocation: faker.address.streetAddress,
	workingHours: () => [],
	referenceInterventionId: faker.datatype.number,
	status: () => "planned",
}));

const file = defineEntity<FileDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "File",
	fileName: faker.system.fileName,
	url: faker.image.imageUrl,
}));

const remark = defineEntity<RemarkDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "Remark",
	createdOn: faker.date.past,
	message: faker.random.words,
	user: {
		email: faker.internet.email,
		id: faker.datatype.uuid,
		userName: faker.internet.userName,
	},
}));

const serviceReport = defineEntity<ServiceReportDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "ServiceReport",
	completed: faker.datatype.boolean,
	createdOn: faker.date.past,
	updatedOn: faker.date.past,
	intervention: oneOf("intervention"),
	startKm: faker.datatype.number,
	endKm: nullable(faker.datatype.number),
	vehicleIdentifier: faker.vehicle.vin,
	workingHours: nullable(() => []),
	articlesRetoured: nullable(faker.random.words),
	articlesToOrder: nullable(faker.random.words),
	executedWork: nullable(faker.random.words),
	pdfUrl: nullable(faker.internet.url),
	pictures: manyOf("file", { nullable: true }),
	remainingWork: nullable(faker.random.words),
	remainingWorkManPower: nullable(faker.datatype.number),
	remarkForCustomer: oneOf("remark", { nullable: true }),
	remarksForInternal: manyOf("remark", { nullable: true }),
	signature: oneOf("file", { nullable: true }),
}));

const user = defineEntity<UserDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "User",
	email: faker.internet.email,
	userName: faker.internet.userName,
}));

export const dictionary = {
	intervention,
	file,
	remark,
	serviceReport,
	user,
};

/**
 * Use this object to define empty entities in request handlers.
 */
export const empty = {
	intervention: {},
	file: {},
	remark: {},
	user: {},
	serviceReport: {
		completed: false,
		createdOn: new Date().toISOString(),
		updatedOn: new Date().toISOString(),
		intervention: null,
		startKm: null,
		endKm: null,
		vehicleIdentifier: null,
		workingHours: null,
		articlesRetoured: null,
		articlesToOrder: null,
		executedWork: null,
		pdfUrl: null,
		pictures: null,
		remainingWork: null,
		remainingWorkManPower: null,
		remarkForCustomer: null,
		remarksForInternal: null,
		signature: null,
	},
};
