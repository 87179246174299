/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInterventionPdfDetailsVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetInterventionPdfDetailsDTO = { __typename?: 'Query', intervention: { __typename?: 'Intervention', id: string, interventionDate: any, dealerId: string, dealerName: string, interventionCode: string, orderNumber: string, warehouseLocation: string, reference: string, model: string, remarks: string, customerAddress: { __typename?: 'InterventionCustomerAddress', name: string, street?: string | null, phoneNumber?: string | null, postalcode?: string | null, city?: string | null }, travelRoute: { __typename?: 'TravelRoute', employees: Array<{ __typename?: 'User', id: string, userName: string }> }, articles: Array<{ __typename?: 'InterventionArticle', reference: string, quantity: number, remarks: string, description: string }>, serviceReport?: { __typename?: 'ServiceReport', id: string, startKm: number, endKm?: number | null, executedWork?: string | null, remainingWork?: string | null, remainingWorkManPower?: number | null, vehicleIdentifier: string, remarksForInternal?: Array<{ __typename?: 'Remark', id: string, message: string }> | null, remarkFromCustomer?: { __typename?: 'Remark', id: string, message: string } | null, workingHours?: Array<{ __typename?: 'TimeBlock', start: any, end?: any | null }> | null, signature?: { __typename?: 'File', id: string, url: string } | null } | null } };


export const GetInterventionPdfDetailsDocumentDTO = gql`
    query getInterventionPdfDetails($id: String!) {
  intervention(id: $id) {
    id
    interventionDate
    customerAddress {
      name
      street
      phoneNumber
      postalcode
      city
    }
    dealerId
    dealerName
    interventionCode
    orderNumber
    warehouseLocation
    reference
    model
    remarks
    travelRoute {
      employees {
        id
        userName
      }
    }
    articles {
      reference
      quantity
      remarks
      description
    }
    serviceReport {
      id
      startKm
      endKm
      executedWork
      remainingWork
      remainingWorkManPower
      vehicleIdentifier
	  articlesRetoured
	  articlesToOrder
      remarksForInternal {
        id
        message
      }
      remarkFromCustomer {
        id
        message
      }
	  remarkForCustomer {
		id
		message
	  }
      workingHours {
        start
        end
      }
      signature {
        id
        url
      }
    }
  }
}
    `;

/**
 * __useGetInterventionPdfDetails__
 *
 * To run a query within a React component, call `useGetInterventionPdfDetails` and pass it any options that fit your needs.
 * When your component renders, `useGetInterventionPdfDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterventionPdfDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInterventionPdfDetails(baseOptions: Apollo.QueryHookOptions<GetInterventionPdfDetailsDTO, GetInterventionPdfDetailsVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInterventionPdfDetailsDTO, GetInterventionPdfDetailsVariablesDTO>(GetInterventionPdfDetailsDocumentDTO, options);
      }
export function useGetInterventionPdfDetailsLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetInterventionPdfDetailsDTO, GetInterventionPdfDetailsVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInterventionPdfDetailsDTO, GetInterventionPdfDetailsVariablesDTO>(GetInterventionPdfDetailsDocumentDTO, options);
        }
export type GetInterventionPdfDetailsHookResult = ReturnType<typeof useGetInterventionPdfDetails>;
export type GetInterventionPdfDetailsLazyQueryDTOHookResult = ReturnType<typeof useGetInterventionPdfDetailsLazyQueryDTO>;
export type GetInterventionPdfDetailsQueryResultDTO = Apollo.QueryResult<GetInterventionPdfDetailsDTO, GetInterventionPdfDetailsVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    