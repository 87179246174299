/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInterventionDetailVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetInterventionDetailDTO = { __typename?: 'Query', intervention: { __typename?: 'Intervention', id: string, interventionDate: any, customerAddress: { __typename?: 'InterventionCustomerAddress', name: string }, serviceReport?: { __typename?: 'ServiceReport', id: string, pdfUrl?: string | null, remarksForInternal?: Array<{ __typename?: 'Remark', id: string, message: string }> | null, pictures?: Array<{ __typename?: 'File', id: string, url: string }> | null } | null } };


export const GetInterventionDetailDocumentDTO = gql`
    query getInterventionDetail($id: String!) {
  intervention(id: $id) {
    id
    interventionDate
    customerAddress {
      name
    }
    serviceReport {
      id
      pdfUrl
      remarksForInternal {
        id
        message
      }
      pictures {
        id
        url
      }
    }
  }
}
    `;

/**
 * __useGetInterventionDetail__
 *
 * To run a query within a React component, call `useGetInterventionDetail` and pass it any options that fit your needs.
 * When your component renders, `useGetInterventionDetail` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterventionDetail({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInterventionDetail(baseOptions: Apollo.QueryHookOptions<GetInterventionDetailDTO, GetInterventionDetailVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInterventionDetailDTO, GetInterventionDetailVariablesDTO>(GetInterventionDetailDocumentDTO, options);
      }
export function useGetInterventionDetailLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetInterventionDetailDTO, GetInterventionDetailVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInterventionDetailDTO, GetInterventionDetailVariablesDTO>(GetInterventionDetailDocumentDTO, options);
        }
export type GetInterventionDetailHookResult = ReturnType<typeof useGetInterventionDetail>;
export type GetInterventionDetailLazyQueryDTOHookResult = ReturnType<typeof useGetInterventionDetailLazyQueryDTO>;
export type GetInterventionDetailQueryResultDTO = Apollo.QueryResult<GetInterventionDetailDTO, GetInterventionDetailVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    