import { PageComponent } from "@aptus/frontend-core";
import { WeekPicker } from "components/form/WeekPicker/WeekPicker";
import { startOfWeek, endOfWeek } from "date-fns";
import { nlBE } from "date-fns/locale";
import { InterventionStatusDTO } from "models/schema";
import { useRoutes, useTravelRoutes } from "domains";
import { Calendar } from "./components/calendar/Calendar";
import { OverviewPageContentStyle, OverviewPageStyle } from "./OverviewPageStyle";
import { GetTravelRoutesVariablesDTO, useGetTravelRoutes } from "./models/getTravelRoutes";
import { GetInterventionsVariablesDTO } from "./models/getInterventions";

const setupFilter = (truck: string, installers: string[] | null, startDate: string, endDate: string) => {
	const filter: GetTravelRoutesVariablesDTO["filter"] = {
		truckId: { equals: truck },
		employee: { containsSome: installers },
		startingHour: {
			gte: startDate,
			lte: endDate,
		},
	};

	return filter;
};

const interventionFilter = (status: InterventionStatusDTO) => {
	const filterStatus = status.length > 0 ? status : undefined;
	const filter: GetInterventionsVariablesDTO["filter"] = {
		status: {
			equals: filterStatus,
		},
	};
	return filter;
};

export const OverviewPage: PageComponent = () => {
	const { setQuery, getQuery } = useRoutes();
	const startDate = getQuery("start") || startOfWeek(new Date(), { locale: nlBE }).toISOString();
	const endDate = getQuery("end") || endOfWeek(new Date(), { locale: nlBE }).toISOString();
	const { travelRoutes, isLoading } = useTravelRoutes(useGetTravelRoutes({
		variables: {
			filter: setupFilter(
				getQuery("truck"),
				getQuery("installer") ? [getQuery("installer")] : null,
				startDate,
				endDate,
			),
			interventionFilter: interventionFilter(getQuery("status") as InterventionStatusDTO),
		},
		fetchPolicy: "no-cache",
	}));

	return (
		<OverviewPageStyle>
			<OverviewPageContentStyle>
				<WeekPicker
					date={getQuery("start")}
					onChange={(start, end) => { setQuery({ start: start?.toISOString(), end: end?.toISOString() }); }}
				/>
				<Calendar dateRange={[startDate, endDate]} items={travelRoutes} isLoading={isLoading} />
			</OverviewPageContentStyle>
		</OverviewPageStyle>
	);
};
