import { TableHead as MUITableHead, TableRow as MUITableRow } from "@mui/material";
import { UIComponent } from "@aptus/frontend-core";

export const TableHead: UIComponent = ({ children }) => (
	<MUITableHead sx={{ fontWeight: "bold" }}>
		<MUITableRow>
			{children}
		</MUITableRow>
	</MUITableHead>
);
