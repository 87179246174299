/* eslint-disable */
import * as Types from '../../../models/schema.d';

import gql from 'graphql-tag';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTravelRoutesVariablesDTO = Types.Exact<{
	filter?: Types.InputMaybe<Types.TravelRouteFilterDTO>;
	interventionFilter?: Types.InputMaybe<Types.InterventionFilterDTO>;
}>

export type GetTravelRoutesDTO = any;

export const GetTravelRoutesDocumentDTO = gql`
	query getTravelRoutes($filter: TravelRouteFilter, $interventionFilter: InterventionFilter) {
		travelRoutes(filter: $filter, sort: { field: "startingHour", order: ASC }) {
			id
			startingHour
			truckId
			interventions(filter: $interventionFilter) {
				id
				interventionDate
				interventionCode
				status
				customerAddress {
					name
					phoneNumber
					city
					postalcode
					street
				}
				serviceReport {
					vehicleIdentifier
					startKm
					endKm
					completed
					remainingWork
					remainingWorkManPower
					workingHours {
					start
					end
					}
				}
			}
			workingHour {
				start
				startKm
				end
				endKm
			}
		}
	}
`

/**
 * __useGetTravelRoutes__
 *
 * To run a query within a React component, call `useGetTravelRoutes` and pass it any options that fit your needs.
 * When your component renders, `useGetTravelRoutes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTravelRoutes({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */

export function useGetTravelRoutes(baseOptions: Apollo.QueryHookOptions<GetTravelRoutesDTO, GetTravelRoutesVariablesDTO>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetTravelRoutesDTO, GetTravelRoutesVariablesDTO>(GetTravelRoutesDocumentDTO, options);
}
export function useGetTravelRoutesLazyQueryDTO(baseOptions: Apollo.QueryHookOptions<GetTravelRoutesDTO, GetTravelRoutesVariablesDTO>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetTravelRoutesDTO, GetTravelRoutesVariablesDTO>(GetTravelRoutesDocumentDTO, options);
}

export type GetTravelRoutesHookResult = ReturnType<typeof useGetTravelRoutes>;
export type GetTravelRoutesLazyQueryHookResult = ReturnType<typeof useGetTravelRoutesLazyQueryDTO>;
export type GetTravelRoutesQueryResultDTO = Apollo.QueryResult<GetTravelRoutesDTO, GetTravelRoutesVariablesDTO>;

export interface PossibleTypesResultData {
	possibleTypes: {
	  [key: string]: string[]
	}
  }
  const result: PossibleTypesResultData = {
"possibleTypes": {}
};

export default result;