import { addDays } from "date-fns";
import { Database } from "mocks/db";

export const populateInterventionCollection = (db: Database): void => {
	const remark = db.remark.create({ message: "Spiegels nog niet geinstalleerd" });
	const sr1 = db.serviceReport.create({ pdfUrl: null });
	const sr2 = db.serviceReport.create({ pdfUrl: null });
	const sr3 = db.serviceReport.create({
		pdfUrl: "http://localhost:8080/https://researchtorevenue.files.wordpress.com/2015/04/1r41ai10801601_fong.pdf",
		remarksForInternal: [remark],
		pictures: [db.file.create({}), db.file.create({})],
		signature: db.file.create({}),
	});
	db.intervention.create({ completed: true, serviceReport: sr1, interventionDate: addDays(new Date(), 1) });
	db.intervention.create({ completed: true, serviceReport: sr2, interventionDate: new Date() });
	db.intervention.create({
		completed: false,
		serviceReport: sr3,
		interventionDate: new Date(),
		executionNumber: "123456",
		travelRoute: {
			employees: [db.user.create(), db.user.create()],
		},
		customerAddress: {
			name: "1 PLA Fam. Heyvaerts-Declerck",
			street: "Burgemeester P. Ceuterickstraat 53 A",
			postalCode: "9890",
			city: "Asper",
		},
		articles: [
			{
				amount: 1,
				barcode: "50K2LP090",
				reference: "OB F50/90 (PROFIEL)",
				description: "DEUR: Structure Beton Dark - KORPUS: Structure Beton Dark",
			},
			{
				amount: 2,
				barcode: "50K2LP060",
				reference: "OB F50/90 (PROFIEL) (600 BR/580 DP/590 HG)",
				description: "Trekkers: Greepprofiel Recht (M3) 30 (zwart mat)",
			},
			{
				amount: 3,
				barcode: "50KOR30",
				reference: "OO 50/30 (OPEN ONDERKAST) (300 BR/480 DP)",
				description: "Trekkers: Greepprofiel Recht (M3) 60 (zwart mat)",
			},
			{
				amount: 4,
				barcode: "50SM180LT19",
				reference: "W34 (BULSAN) 180 (ENK WASK. L. 1 KR.) TOP SOLID WIT (1805x515x15) MO6880B500 180,5CM 1L MAT (1805 LG/ 515 DP/ 15 DK)",
				description: "Trekkers: Greepprofiel Recht (M3) 90 (zwart mat)",
			},
		],
	});
	db.intervention.create({
		interventionDate: new Date("02 April, 2022, 08:00"),
		completed: false,
		serviceReport: null,
	});
	db.intervention.create({
		interventionDate: new Date("03 April, 2022, 09:00"),
		completed: false,
		serviceReport: null,
	});
	db.intervention.create({ completed: true, serviceReport: null, interventionDate: new Date("04 April, 2022, 09:00") });
	db.intervention.create({ completed: true, serviceReport: null, interventionDate: new Date("04 April, 2022, 09:00") });
	db.intervention.create({ completed: true, serviceReport: null, interventionDate: new Date("06 April, 2022, 09:00") });
};
