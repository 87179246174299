import { UIComponent } from "@aptus/frontend-core";
import {
	Autocomplete,
	TextField,
	TextFieldProps,
} from "@mui/material";
import { format } from "date-fns";
import { nlBE } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useInterventions, useRoutes } from "domains";
import { Intervention } from "hooks/interventions/models/intervention";
import { GetInterventionsVariablesDTO, useGetInterventions } from "pages/overviewPage/models/getInterventions";
import { getStatusColor, showStatusIcon } from "utils/serviceReportUtils";
import {
	DropdownText,
	FooterContainer,
	ListItem,
	Text,
	TextTitle,
} from "./SearchFieldStyle";
import { PaperComponent } from "./PaperComponent";

type SearchFieldProps = TextFieldProps;

const setupFilter = (search: string) => {
	const filter: GetInterventionsVariablesDTO["filter"] = {
		OR: [
			{
				customerAddress: {
					OR: [
						{ name: { contains: search } },
						{ street: { contains: search } },
						{ postalcode: { contains: search } },
						{ city: { contains: search } },
					],
				},
			},
			{ dealerName: { contains: search } },
			{ orderNumber: { contains: search } },
			{ travelRoute: { employee: { containsSome: search ? [search] : null } } },
		],
	};

	return filter;
};

export const SearchField: UIComponent<SearchFieldProps> = () => {
	const { setQuery, getQuery } = useRoutes();
	const navigate = useNavigate();

	const { interventions, isLoading } = useInterventions(useGetInterventions({
		variables: {
			filter: setupFilter(getQuery("search")),
		},
		skip: getQuery("search").length === 0,
	}));

	return (
		<Autocomplete
			disablePortal
			id="combo-box-demo"
			filterOptions={(inter: Intervention[]) => inter}
			options={interventions}
			sx={{ width: 400 }}
			getOptionLabel={(option) => option.customer.name}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="standard"
					placeholder={i18next.t("filter.searchByCustomerOrIntervention")}
				/>
			)}
			loading={isLoading}
			onInputChange={(_, newValue) => {
				setQuery({ search: newValue });
			}}
			onChange={(_, newValue) => {
				if (newValue) {
					navigate(`${newValue.url.replace(":travelrouteid", newValue.travelRouteId)}`);
				}
			}}
			renderOption={(props, option) => (
				<li {...props} key={option.id}>
					<div style={{ display: "flex", flex: 1, backgroundColor: "grey" }}>
						<ListItem statusColor={getStatusColor(option.status) ?? "white"}>
							<TextTitle>{option.customer.name}</TextTitle>
							<Text>{option.installers?.[0].name}</Text>
							<FooterContainer>
								<p style={{ color: "black" }}>{format(option.date, "dd MMM yyyy", { locale: nlBE })}</p>
								{showStatusIcon(option.status)}
							</FooterContainer>
						</ListItem>
					</div>
				</li>
			)}
			PaperComponent={PaperComponent}
			noOptionsText={<DropdownText>{i18next.t("filter.noResults")}</DropdownText>}
			loadingText={<DropdownText>{i18next.t("filter.loading")}</DropdownText>}
		/>
	);
};
