import styled from "styled-components";

interface ListItemProps {
	statusColor: string;
}

export const ListItem = styled.div<ListItemProps>`
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: white;
	padding: 10px;
	border-left: 5px solid;
	border-left-color: ${({ statusColor }) => statusColor};
`;

export const TextTitle = styled.p`
	color: black;
	margin-bottom: 10px;
`;

export const Text = styled.p`
	color: black;
	font-style: normal;
	margin-bottom: 20px;
	opacity: 0.5;
`;

export const FooterContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const DropdownText = styled.p`
	color: black;
`;
