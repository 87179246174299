import { UIComponent } from "@aptus/frontend-core";
import { SearchField } from "components/form/SearchField/SearchField";
import { Select } from "components/form/Select/Select";
import i18next from "i18next";
import { ThemeProvider } from "@mui/material";
import { DarkTheme } from "theme";
import { Container, FilterBarStyle, Seperator } from "./filterBarStyle";

export interface Item {
	label: string;
	value: string;
}

export type Status = "planned" | "inProgress" | "completed" | "notCompleted";

interface Props {
	search: string;
	installer: string;
	truck: string;
	trucks: Item[];
	status: Status;
	installers: Item[];
	setSearch: (search: string) => void;
	setInstaller: (installer: string) => void;
	setTruck: (truck: string) => void;
	setStatus: (status: Status) => void;
}

export const FilterBar: UIComponent<Props> = ({
	search,
	installer,
	truck,
	trucks,
	installers,
	setSearch,
	setInstaller,
	setTruck,
	status,
	setStatus,
}) => (
	<FilterBarStyle>
		<ThemeProvider theme={DarkTheme}>
			<Container>
				<Select
					defaultValue={installer}
					onChange={(e) => setInstaller(e.target.value as string)}
					minWidth={200}
					label={i18next.t("filter.filterOnInstaller")}
					placeholder={i18next.t("filter.filterOnInstaller")}
					items={[
						{ label: i18next.t("filter.empty"), value: "" },
						...installers,
					]}
				/>
				<Seperator />
				<Select
					defaultValue={truck}
					onChange={(e) => setTruck(e.target.value as string)}
					label={i18next.t("filter.filterByCar")}
					placeholder={i18next.t("filter.filterByCar")}
					minWidth={200}
					items={[
						{ label: i18next.t("filter.empty"), value: "" },
						...trucks,
					]}
				/>
				<Seperator />
				<Select
					defaultValue={status}
					onChange={(e) => setStatus(e.target.value as Status)}
					label={i18next.t("filter.filterByState")}
					placeholder={i18next.t("filter.filterByState")}
					minWidth={200}
					items={[
						{ label: i18next.t("filter.empty"), value: "" },
						{ label: i18next.t("filter.planned"), value: "planned" },
						{ label: i18next.t("filter.inProgress"), value: "inProgress" },
						{ label: i18next.t("filter.completed"), value: "completed" },
						{ label: i18next.t("filter.notCompleted"), value: "completedWithRemainingWork" },
					]}
				/>
			</Container>
			<div>
				<SearchField
					defaultValue={search}
					onChange={(e) => setSearch(e.currentTarget.value)}
					sx={{ minWidth: 300 }}
					label={i18next.t("filter.searchByCustomerOrIntervention")}
					placeholder={i18next.t("filter.searchByCustomerOrIntervention")}
				/>
			</div>
		</ThemeProvider>
	</FilterBarStyle>
);
