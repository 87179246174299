import styled from "styled-components";

export const Container = styled.div`
	display: flex;
`;

export const FilterBarStyle = styled.div`
	display: flex;
	flex-wrap: wrap;

	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: 50%;
`;

export const Seperator = styled.div`
	height: 50%;
	width: 1px;
	background-color: white;
	align-self: flex-end;
	margin-left: 25px;
	margin-right: 25px;
	margin-bottom: 10px;
	opacity: .3;
`;
